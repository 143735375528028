/* Overall Navbar Container */
.unauthn-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #141414;
  padding: 0 2rem;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 0.5px solid #3e3e3e;
}

/* Left Section: Logo */
.unauthn-navbar-left {
  display: flex;
  align-items: center;
}

.unauthn-navbar-logo {
  height: 50px;
  width: auto;
}

/* Middle Section: Nav Links */
.unauthn-navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
  gap: 1.7rem;
}

.unauthn-navbar-link {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
}

/* For internal links within nav items */
.unauthn-internal-link {
  color: #fff;
  text-decoration: none;
}

/* Underline Hover Effect */
.unauthn-underline-hover {
  position: relative;
}

.unauthn-underline-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}

.unauthn-underline-hover:hover::after {
  width: 100%;
}

/* Right Section: Divider, Login, Sign Up */
.unauthn-navbar-right {
  display: flex;
  align-items: center;
}

.unauthn-navbar-divider {
  width: 0.5px;
  height: 32px;
  background-color: #3e3e3e;
  margin-right: 1rem;
}

.unauthn-login-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  margin-right: 1.3rem;
}

/* Sign Up Button */
.unauthn-signup-btn {
  background-color: #fff;
  color: #141414;
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s ease, color 0.2s ease;
  border: 1px solid #fff;
  overflow: hidden;  /* Ensures the arrow overflow is clipped */
}

/* Arrow Container */
.unauth-arrow-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-left: 0.5rem;
  height: 1em; /* Sets a fixed height to clip the moving arrows */
}

/* Both arrows */
.unauth-arrow {
  display: inline-block;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Primary arrow (initially visible) */
.unauth-arrow-primary {
  position: relative;
  opacity: 1;
}

/* Secondary arrow (hidden above) */
.unauth-arrow-secondary {
  position: absolute;
  top: -100%;
  left: 0;
  opacity: 0;
}

/* Hover Animation for Sign Up Button */
.unauth-signup-btn:hover .unauth-arrow-primary {
  transform: translateY(100%);
  opacity: 0;
}

.unauth-signup-btn:hover .unauth-arrow-secondary {
  transform: translateY(100%);
  opacity: 1;
}

/* src/HomePage.css */

/* General styles */
body {
  background-color: #161616;
  color: #fff;
}

/* Homepage container */
.hp-homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Top fold section */
.hp-topfold-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #161616;
  color: #fff;
  max-width: 1200px;
}

.hp-topfold-content {
  flex: 1;
  text-align: left;
}

.hp-main-header {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hp-main-header .highlight {
  color: #4add80;
}

.hp-sub-header {
  font-size: 1.5rem;
  color: #bbb;
  margin-bottom: 40px;
}

/* CTA buttons container */
.hp-cta-buttons {
  display: flex;
  gap: 15px;
  width: 90%;
}

.hp-cta-buttons button {
  flex: 1;
  min-width: 0; /* helps with overflow issues */
}

/* Email button styling */
.hp-cta-button.email-button {
  padding: 10px 0;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background-color: #3da86b;
  color: #fff;
  transition: background-color 0.3s ease;
}

.hp-cta-button.email-button:hover {
  background-color: #32975c;
}

/* Google button styling replicating the provided design */
.hp-google-button {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #4184f3; /* Updated color */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hp-google-button:hover {
  background-color: #357ae8; /* Darker shade on hover */
}

.hp-google-logo-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.hp-google-logo-left {
  width: 24px;
  height: 24px;
}

.hp-google-signup-text {
  flex: 1;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

/* Topfold media container (video) */
.hp-topfold-media-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10%;
  width: 100%;
  max-width: 600px;
}

/* Gradient overlays on left and right */
.hp-topfold-media-container::before,
.hp-topfold-media-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  pointer-events: none;
  z-index: 2;
}

.hp-topfold-media-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0));
}

.hp-topfold-media-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0));
}

.hp-topfold-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Highlighted text with transition */
.highlight {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

/* Fade-in and slide-up animation */
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-out and slide-up animation */
@keyframes fadeOutSlideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.highlight.fade-in {
  animation: fadeInSlideUp 0.5s forwards;
}

.highlight.fade-out {
  animation: fadeOutSlideUp 0.5s forwards;
}

.hp-main-header .highlight {
  color: #4add80;
  transition: color 0.5s ease-in-out;
}

/* Get Started Pill */
.hp-get-started-pill {
  display: flex;
  align-items: center;
  background-color: #3da86b;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hp-get-started-pill:hover {
  background-color: #32975c;
  transform: translateY(-2px);
}

.hp-pill-badge {
  background-color: #fff;
  color: #3da86b;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  margin-right: 10px;
  font-weight: bold;
}

.hp-pill-text {
  flex-grow: 1;
}

.hp-pill-arrow {
  margin-left: 7px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  margin-bottom: 3px;
}

.hp-get-started-pill:hover .hp-pill-arrow {
  transform: translateX(5px);
}

.hp-main-header {
  margin-top: 10px;
}

/* Prompt Workspace Image Container */
.hp-prompt-workspace-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

/* Prompt Workspace Image */
.hp-prompt-workspace-image {
  max-width: 100%;
  height: auto;
  width: 1200px;
  border-radius: 10px;
}

/* Wrapper for prompt workspace */
.hp-prompt-workspace-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  text-align: center;
}

.hp-prompt-workspace-subtitle {
  font-size: 1.2rem;
  color: #bbb;
  margin-bottom: 10px;
  max-width: 800px;
  margin-top: 5px;
}

/* Processing CSS */
.processing-container {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.processing-text {
  color: white;
  text-align: center;
  font-size: 20px;
}

.processing-image-logo {
  width: 200px;
  height: auto;
}

.process-fixed-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

/* Responsiveness */

/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
  .hp-topfold-section {
    flex-direction: column;
    padding: 40px;
    align-items: center;
    text-align: center;
  }

  .hp-topfold-content {
    width: 100%;
    max-width: 800px;
  }

  .hp-main-header {
    font-size: 2.3rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .hp-sub-header {
    font-size: 1rem;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .hp-prompt-workspace-subtitle {
    padding: 0 30px;
  }

  .hp-get-started-pill {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .hp-pill-badge {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

  .hp-pill-arrow {
    font-size: 1rem;
  }

  .hp-cta-buttons {
    margin: 0 auto;
    width: 60%;
    flex-direction: column;
  }

  .hp-topfold-media-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 40px;
  }

  .hp-prompt-workspace-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .hp-prompt-workspace-image {
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  .video-carousel-container h2,
  .podcast-usecase-container h2,
  .faq-container h2 {
    font-size: 2rem;
  }

  .footer-container p,
  .cookie-bar-container p {
    font-size: 0.9rem;
  }

  .processing-text {
    font-size: 18px;
  }

  .process-fixed-text {
    font-size: 14px;
  }
}

/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  .hp-topfold-section {
    flex-direction: column;
    padding: 40px;
    align-items: center;
    text-align: center;
  }

  .hp-topfold-content {
    width: 100%;
    max-width: 800px;
  }

  .hp-main-header {
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .hp-sub-header {
    font-size: 1.3rem;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  .hp-prompt-workspace-subtitle {
    margin-bottom: 12px;
  }

  .hp-get-started-pill {
    padding: 8px 16px;
    font-size: 0.9rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .hp-pill-badge {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

  .hp-pill-arrow {
    font-size: 1rem;
  }

  .hp-topfold-media-container {
    width: 100%;
    max-width: 700px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }


  .hp-cta-buttons {
    margin: 0 auto;
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;

  }

  .hp-prompt-workspace-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .hp-prompt-workspace-image {
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  .video-carousel-container h2,
  .podcast-usecase-container h2,
  .faq-container h2 {
    font-size: 2rem;
  }

  .footer-container p,
  .cookie-bar-container p {
    font-size: 0.9rem;
  }

  .processing-text {
    font-size: 18px;
  }

  .process-fixed-text {
    font-size: 14px;
  }
}

.faq-section-pricing {
  background-color: #161616;
  color: #ffffff;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

.faq-section-pricing h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.faq-item {
  background-color: #141414;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 0.5px solid #3e3e3e;

}

.faq-item:hover {
  background-color: #161616;

}

.faq-item h3 {
  padding: 20px;
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.caret {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
  margin-bottom: 10px
}

.caret.rotate {
  transform: rotate(180deg);
}

.faq-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.1s ease, padding 0.1s ease;
  padding: 0 20px;
  text-align: left;
  color: #aaaaaa;
}

.faq-content.show {
  max-height: 1000px;
  padding: 0 20px 20px 20px;
}

.faq-content p {
  margin: 0;
  line-height: 1.6;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.faq-content.show p {
  opacity: 1;
}

@media (max-width: 768px) {

    .faq-section-pricing {
      width: 90%;
      margin-bottom: -40px;
    }
  }
  
  
  
  
  /* Tablets and small desktops (769px to 1280px) */
  @media (min-width: 769px) and (max-width: 1280px) {
  
    .faq-section-pricing {
    width: 80%;
    }

  
  }
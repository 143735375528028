/* ==============================
   CONTAINER & TOGGLE BUTTON
   ============================== */

   .unauthn-product-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .unauthn-product-dropdown-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font: inherit;
    display: flex;
    align-items: center;
  }
  
  /* ==============================
     DROPDOWN MENU
     ============================== */
  
     .unauthn-product-dropdown-menu {
      position: fixed;
      left: 50%;
      top: calc(90px); /* Adjust based on your navbar (and announce bar, if any) height */
      display: flex; /* Two columns: left for Features, right for Quick Links */
      min-width: 800px;
      background-color: #141414;
      color: #fff;
      border: 1px solid #3e3e3e;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      z-index: 2000;
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
      transition: opacity 0.3s ease, transform 0.5s ease;
      visibility: hidden;
    }
    
    .unauthn-product-dropdown-menu.open {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      visibility: visible;
    }
    
  
  /* Arrow transition */
  .unauthn-dropdown-arrow {
    display: inline-block;
    font-size: 0.75rem;
    margin-left: 0.2rem;
    transition: transform 0.3s ease;
    transform: rotate(0deg) scaleY(0.7);
  }
  
  .unauthn-dropdown-arrow.open {
    transform: rotate(180deg) scaleY(0.7);
  }
  
  /* ==============================
     SHARED COLUMN STYLES
     ============================== */
  
  .unauthn-dropdown-column {
    border-left: 0.5px solid #3e3e3e;
    display: flex;
    flex-direction: column;
  }
  
  .unauthn-dropdown-column:first-child {
    border-left: none; /* No left border on the first column */
  }
  
  /* Section headings inside columns */
  .unauthn-dropdown-heading {
    background-color: #202020;
    margin: 0;
    padding: 1rem 1.25rem;
    font-size: 0.9rem;
    font-weight: 200;
    color: #fff;
    border-bottom: 0.5px solid #3e3e3e;
    text-align: left;
  }
  
  /* ==============================
     LEFT COLUMN: FEATURES (GRID STYLE)
     ============================== */
  
  .unauthn-dropdown-column-left {
    flex: 2; /* Make the Features column a bit wider if you like */
  }
  
  .unauthn-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjust the min width as needed */
    gap: 1rem;
    padding: 1rem;
  }
  
  .unauthn-feature-tile {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: #141414; /* Flat background color */
    border: 1px solid #3e3e3e; /* Thin border to separate grid items */
    border-radius: 4px; /* Removed rounded corners for a flat look */
    padding: 1rem;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    cursor: pointer;
    margin: 0; /* Remove extra margin if any */
    min-height: 90px;
    text-decoration: none; /* Remove underline for link elements */
  }
  
  .unauthn-feature-tile:hover {
    background-color: #2a2a2a;
    border-color: #5a5a5a;
  }
  
  .unauthn-feature-icon {
    width: 48px;
    height: 48px;
    margin-right: 0.75rem;
    flex-shrink: 0;
    transition: transform 0.2s ease;
  }
  
  .unauthn-feature-tile:hover .unauthn-feature-icon {
    transform: scale(1.1);
  }
  
  .unauthn-feature-title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 0.25rem 0;
    color: #fff;
  }
  
  .unauthn-feature-description {
    margin: 0;
    font-size: 0.85rem;
    color: #ccc;
    margin-top: 5px;
  }
  
  /* ==============================
     RIGHT COLUMN: QUICK LINKS
     ============================== */
  
  .unauthn-quick-links-column {
    background-color: #202020;
    flex: 1; /* Original ratio, narrower than the left column */
  }
  
  .unauthn-dropdown-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem 1.25rem;
    border-bottom: 0.5px solid #3e3e3e;
    border-right: 0.5px solid #3e3e3e;
    text-align: left;
    transition: background-color 0.2s ease;
    cursor: pointer;
    text-decoration: none; /* Remove underline */
    color: inherit;       /* Inherit text color */
  }
  
  .unauthn-dropdown-item:last-child {
    border-bottom: none;
  }
  
  .unauthn-dropdown-item:hover {
    background-color: #1b1b1b;
  }
  
  .unauthn-dropdown-item a {
    color: #fff;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 400;
    transition: color 0.2s ease;
  }
  
  .unauthn-dropdown-item a:hover {
    color: #fff;
    opacity: 0.8;
  }

  /* Delete button styled like your other settings buttons */
  .warning-delete-button {
    background-color: #2c2f2f;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .warning-delete-button:hover {
    background-color: #3a3d3d;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal overlay */
  .warning-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal container - no horizontal padding so borders span edge-to-edge */
  .warning-modal {
    background: #141414;
    border-radius: 14px;
    width: 90%;
    max-width: 500px;
    color: #fff;
    text-align: left;
    overflow: hidden; /* ensures border-radius remains visible */
    position: 15px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.5); /* Slight adjustment */

  }
  
  /* Header with bottom border, containing the title and X button */
  .warning-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px; /* vertical, horizontal to align text nicely */
    border-bottom: 0.5px solid #3a3a3a;
  }
  
  .warning-modal-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  /* Close (X) button in top right */
  .warning-modal-close {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    line-height: 1;
    padding: 0;
  }
  
  .warning-modal-close:hover {
    opacity: 0.7;
  }
  
  /* Content area with bullet points, etc. */
  .warning-modal-content {
    padding: 16px 20px;
  }
  
  .warning-list {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .warning-list li {
    margin-bottom: 8px;
    line-height: 1.4;
  }
  
  /* Footer with top border, containing the action buttons */
  .warning-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 16px 20px;
    border-top: 0.5px solid #3a3a3a;
  }
  
  /* Buttons inside the modal footer */
  .warning-confirm-delete-button,
  .warning-cancel-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  
  /* Confirm Deletion button */
  .warning-confirm-delete-button {
    background-color: #e53935;
    color: #fff;
  }
  
  .warning-confirm-delete-button:hover {
    background-color: #c62828;
  }
  
  /* Cancel button */
  .warning-cancel-button {
    background-color: #2c2f2f;
    color: #fff;
  }
  
  .warning-cancel-button:hover {
    background-color: #3a3d3d;
  }
  
.connectui-info-box {
    background: #161616;
    color: #fff;
    border-radius: 5px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column; /* Change to column to stack elements */
    justify-content: left; /* Align content to the left */
    width: 450px;
    height: auto; /* Adjust height to fit content */
    border: 0.5px solid #3e3e3e;
    overflow: hidden; /* Ensure content doesn't overflow */
}

.connectui-center-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    margin-bottom: 10px; /* Adds space between center section and processing container */
}


.connectui-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.connectui-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align all child elements */
    justify-content: center; /* Center elements vertically if needed */
    margin-left: 25px; /* Remove any left margin if it was previously used */
    text-align: center; /* Center text inside the elements */
}


.connectui-distribution-title {
    color: #fff;
    font-size: 1.5em;
    margin: 5px 0;
    text-align: left; /* Align text to the left */
    margin-bottom: 0px;
}

.connectui-connectYouTube-button {
    background-color: #54565c;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    padding: 12px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    margin-top: 20px; /* Adds space between the title and the button */
}

.connectui-connectYouTube-button.connected {
    cursor: default;
    background-color: #ffffff;
    opacity: 1.0;
}

.connectui-connectyoutube {
    width: 33px;
    height: 25px;
}

.connectui-channel-handle {
    margin-top: 10px;
    font-size: 12px;
    color: #aaa;
}




/* New CSS classes for the added section */
.connectui-processing-container {
    background-color: #141414;
    color: white;
    border-radius: 4px;
    padding: 15px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #3e3e3e;
    margin-top: 10px; /* Adds space between info box and processing container */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow: hidden; /* Ensure content doesn't overflow */
}

.link-no-underline {
    text-decoration: none;
}

.connectui-processing-content {
    text-align: left;
}

.connectui-processing-card {
    display: flex;
    align-items: center;
    gap: 15px;
}

.connectui-subtitle {
    margin-top: 0;
    font-size: 0.9rem;
    color: #aaa;
}

.connectui-title {
    font-size: 1.2rem;
    margin: 7px 0;
    font-weight: bold;
}

.connectui-description {
    font-size: 0.9rem;
    color: #ddd;
}

.connectui-upload-icon {
    width: 65px; /* Adjust the width to make the image smaller */
    height: 65px; /* Adjust the height to maintain aspect ratio */
}

.connectui-upload-language {
    width: 70px; /* Adjust the width to make the image smaller */
    height: 70px; /* Adjust the height to maintain aspect ratio */
}


.connectui-processing-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.connectui-processing-start-btn {
    background-color: transparent;
    color: #4add80;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.connectui-processing-start-btn:hover {
    text-decoration: underline;
}

/* Media Queries */
@media (max-width: 768px) {
    .connectui-info-box {
        width: 250px; /* Adjust width for mobile */
        height: auto; /* Adjust height for mobile */
        padding: 15px 30px;
        border-radius: 5px 5px 0px 0px;

    }
    .connectui-avatar {
        width: 100px;
        height: 100px;
    }
    .connectui-distribution-title {
        font-size: 1.2em;
    }
    .connectui-connectYouTube-button {
        padding: 8px;
        font-size: 0.8rem;
    }
    .connectui-connectyoutube {
        width: 25px;
        height: 18px;
    }
    .connectui-processing-container {
        width: 250px; /* Adjust width for mobile */
        padding: 15px 20px;
    }
    .connectui-subtitle {
        font-size: 0.8rem;
    }
    .connectui-title {
        font-size: 0.9rem;
    }
    .connectui-description {
        font-size: 0.7rem;
    }

    .connectui-upload-icon {
        width: 45px; /* Adjust the width to make the image smaller */
        height: 45px; /* Adjust the height to maintain aspect ratio */    
    }

    .connectui-upload-language {
        width: 55px; /* Adjust the width to make the image smaller */
        height: 55px; /* Adjust the height to maintain aspect ratio */
    }

    .connectui-processing-start-btn {
        font-size: 0.7rem;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .connectui-info-box {
        width: 400px; /* Adjust width for tablet */
        height: auto; /* Adjust height for tablet */
        padding: 18px 35px;
    }
    .connectui-avatar {
        width: 155px;
        height: 155px;
    }
    .connectui-distribution-title {
        font-size: 1.3em;
    }
    .connectui-connectYouTube-button {
        padding: 10px;
        font-size: 0.9rem;
    }
    .connectui-connectyoutube {
        width: 28px;
        height: 22px;
    }
    .connectui-processing-container {
        width: 400px; /* Adjust width for tablet */
        padding: 18px 35px;
    }
    .connectui-subtitle {
        font-size: 0.85rem;
    }
    .connectui-title {
        font-size: 1.1rem;
    }
    .connectui-description {
        font-size: 0.85rem;
    }
    .connectui-upload-icon {
        width: 50px; /* Adjust the width to make the image smaller */
        height: 50px; /* Adjust the height to maintain aspect ratio */    
    }
    .connectui-upload-language {
        width: 60px; /* Adjust the width to make the image smaller */
        height: 60px; /* Adjust the height to maintain aspect ratio */
    }
    .connectui-processing-start-btn {
        font-size: 0.75rem;
    }
}

@media (min-width: 1281px) and (max-width: 1800px) {
    .connectui-info-box {
        width: 420px; /* Adjust width for this screen range */
        height: auto; /* Adjust height for this screen range */
        padding: 20px 38px;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-avatar {
        width: 140px;
        height: 140px;
    }
    .connectui-distribution-title {
        font-size: 1.4em;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-connectYouTube-button {
        padding: 11px;
        font-size: 1rem;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-connectyoutube {
        width: 30px;
        height: 23px;
    }
    .connectui-processing-container {
        width: 420px;
        padding: 20px 30px;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-subtitle {
        font-size: 0.87rem;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-title {
        font-size: 1.15rem;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-description {
        font-size: 0.87rem;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
    .connectui-upload-icon {
        width: 50px; /* Adjust the width to make the image smaller */
        height: 50px; /* Adjust the height to maintain aspect ratio */    
    }
    .connectui-upload-language {
        width: 60px; /* Adjust the width to make the image smaller */
        height: 60px; /* Adjust the height to maintain aspect ratio */
    }
    .connectui-processing-start-btn {
        font-size: 0.78rem;
        overflow: hidden; /* Ensure content doesn't overflow */
    }
}


.footer-container {
    display: flex;
    justify-content: space-between;
    background-color: #161616;
    padding: 20px 40px;
    font-size: 16px; /* Increased font size */
    color: #606060;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 0 auto;
  }
  
  .footer-branding {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .footer-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 30px 0 10px 0;
  }
  
  .footer-logo {
    height: 45px;
    width: auto;
    margin-left: 10px;
  }

  .footer-brand-description {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
    max-width: 290px;
    font-size: 15px;
    color: #cccccc;
  }
  
  .footer-bumpups-brand {
    color: #ffffff;
    font-size: 26px;
    font-weight: bold;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .footer-bumpups-brand-color {
    color: #16c391;
    font-size: 26px;
    font-weight: bold;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
    color: #cccccc;
    text-align: left;
  }
  
  .footer-section h4 {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
  }
  
  .footer-links {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 12px;
    cursor: pointer;
    transition: color 0.2s;
    font-weight: 400;
  }
  
  .footer-links li:hover {
    color: #ffffff; /* More obvious hover */
  }
  
  .footer-timestamp-highlight {
    color: #4add80;
    margin-bottom: 10px;
  }
  
  .footer-bottom {
    margin-top: 5px;
    font-size: 14px; /* Increase footer bottom text size */
    color: #cccccc;
    font-weight: bold;
    padding: 80px 0 20px 50px;
    width: 100%;
    text-align: left;
  }
  
  .footer-bottom a {
    margin: 0 10px;
    color: #cccccc;
    text-decoration: none;
  }
  
  .footer-bottom a:hover {
    text-decoration: underline;
    color: #ffffff;
  }
  
  .footer-social-media-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .footer-social-media-icons a:nth-child(1) img {
    width: 38px;
    height: 38px;
  }
  
  .footer-social-media-icons a:nth-child(2) img {
    width: 36px;
    height: 36px;
    margin-left: 8px;
  }
  
  .footer-contact-link {
    text-decoration: none;
    color: #cccccc;
    font-weight: 400;
  }
  
  .footer-contact-link:hover {
    text-decoration: none;
    color: #ffffff;
  }
  
  /* External Link Arrow Indicator for text links */
  .footer-external-link::after {
    content: "↗";
    margin-left: 4px;
    font-size: 0.8em;
    transform: rotate(10deg);
  }
  
  /* Remove arrow for any images or icons if necessary */
  .footer-social-media-icons a::after {
    content: none;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
    .footer-section {
      flex-direction: row;
    }
    .footer-mobile {
      margin-top: 30px;
    }
    .footer-bottom {
      font-size: 14px;
      text-align: center; /* Center the mobile footer bottom */
      padding: 20px 0;
    }
    
  }
  
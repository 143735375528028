/* Base Styles for the Cookie Bar */
.cookie-bar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  background-color: #161616;
  color: #ffffff;
  text-align: left;
  z-index: 1000;
  border-top: 1px solid #3e3e3e;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
  box-sizing: border-box;
  opacity: 0; /* Start hidden */
  transform: translateY(100%);
}

/* Slide-up animation */
.slide-up {
  animation: slideUpAnimation 0.5s forwards;
}

@keyframes slideUpAnimation {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Wrapper for Content */
.cookie-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Cookie Text */
.cookie-text {
  flex: 1;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  padding-right: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.cookie-text a {
  color: #3da6fe;
  text-decoration: underline;
}

.cookie-text a:hover {
  color: #2f94e5;
}

/* Button Wrapper */
.button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Accept Cookies Button */
.accept-cookies-btn {
  background-color: #3da6fe;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-cookies-btn:hover {
  background-color: #2f94e5;
}

/* Later Button */
.later-btn {
  background-color: transparent;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 15px 25px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.later-btn:hover {
  background-color: #161616;
  border-color: #9b9ea3;
}

/* Responsive Styles */

/* Tablet Devices (width between 768px and 1024px) */
@media (max-width: 1024px) and (min-width: 769px) {
  .cookie-bar {
    padding: 15px 25px;
  }

  .cookie-text {
    font-size: 16px;
    padding-right: 15px;
  }

  .accept-cookies-btn,
  .later-btn {
    font-size: 16px;
    padding: 12px 20px;
  }
}

/* Mobile Devices (width 768px and below) */
@media (max-width: 768px) {
  .cookie-bar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
  }

  .cookie-content-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .cookie-text {
    font-size: 14px;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .button-wrapper {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .accept-cookies-btn,
  .later-btn {
    width: 100%;
    font-size: 14px;
    padding: 10px 15px;
  }

  .later-btn {
    border: none;
    background-color: transparent;
    border: 2px solid #ffffff;
  }

  .later-btn:hover {
    background-color: #9b9ea3;
    border-color: #9b9ea3;
  }
}

/* Main container for settings */
.account-settings-container {
    background-color: #141414;
    border-radius: 8px;
    padding: 24px;
    font-family: Arial, sans-serif;
    color: #fff;
    margin: 0;
    border: 0.5px solid #2a2a2a;
  }
  
  /* Container for avatar image and upload button */
  .avatar-container {
    position: relative;
    display: inline-block;
  }
  
  /* Avatar image */
  .avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #2a2a2a;
  }
  
  /* Avatar upload button remains unchanged */
  .avatar-upload-button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #2c2f2f;
    border: none;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.2s;
    opacity: 0.8;
  }
  
  .avatar-upload-button:hover {
    opacity: 1;
  }
  
  .avatar-upload-button img {
    width: 14px;
    height: 14px;
  }
  
   
  /* Plan info */
  .plan-info {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  .plan-info span {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
  
  /* 
    SELECT WRAPPER 
    - Hides native arrow 
    - Adds custom arrow 
  */
  .select-wrapper {
    position: relative;
    display: inline-block;
  }
  
  /* Hide the default native arrow */
  .language-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #2c2f2f;
    border: 1px solid #2f2f2f;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    width: 220px;
  }
  
  .language-dropdown::-ms-expand {
    display: none;
  }
  
  /* Custom arrow using pseudo-element */
  .select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
  }
  
  .language-settings-field {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  @media (max-width: 768px) {


    .language-settings-field {
      flex-direction: column;
      align-items: flex-start; /* Optional: aligns items to the start */
    }
  

  }
.editBump-section-container {
    background-color: #161616;
    padding: 0px;
    color: #ffffff;
    
}

.editBump-sections-wrapper {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #3e3e3e;
    border-radius: 0px 0px 5px 5px;
}

.editBump-section-content,
.editBump-section-sidebar {
    padding: 30px;    
}

.editBump-section-content {
    width: 100%;
}
/* USER EDIT SECTION CONTENT */
/* USER EDIT SECTION CONTENT */
/* USER EDIT SECTION CONTENT */
/* USER EDIT SECTION CONTENT */
/* USER EDIT SECTION CONTENT */


.editBump-title-wrapper .editBump-section-title-input,
.editBump-description-wrapper .editBump-section-description {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px; /* Adjust size as needed */
    line-height: 1.5;
    color: #fff; /* Adjust color as needed */
}



.editBump-section-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.editBump-section-input-group {
    margin-bottom: 30px;
    width: 100%;
}

.editBump-section-title-label,
.editBump-section-description-label,
.editBump-section-thumbnail-label,
.editBump-section-tags-label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.editBump-section-title-input,
.editBump-section-description,
.editBump-section-tags-input {
    width: 100%;
    padding: 10px;
    background-color: #1d1d1d;
    color: #ffffff;
    border: 0.5px solid #3e3e3e;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
}

.editBump-section-title-input:focus,
.editBump-section-description:focus,
.editBump-section-tags-input:focus {
    outline: none;
    border: 1px solid #ffffff;
}

.editBump-title-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.editBump-section-title-input {
    resize: vertical;
    min-height: 40px; /* Minimum height */
    max-height: 60px; /* Maximum height */
}

.editBump-title-button {
    border-radius: 8px;
    padding: 13px 18px;
    background-color: #ffffff; /* White background */
    color: black;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease, opacity 0.3s ease; /* Added opacity transition */
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.editBump-title-button:hover {
    background-color: #dddddd; /* Light grey background on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.editBump-title-button:disabled {
    opacity: 0.5;
    cursor:default;
    transition: opacity 0.3s ease; /* Ensure the transition is smooth when disabled */
    background-color: #272727;
    color: #fff;

}




.editBump-description-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.editBump-title-header,
.editBump-description-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}


.editBump-save-template-button {
    border-radius: 8px;
    padding: 13px 18px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.editBump-save-template-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}


.editBump-save-template-button:disabled {
    opacity: 0.5;
    cursor:default;
    pointer-events: none;
}

.editBump-save-template-button:hover:not(:disabled) {
    background-color: #777777; /* Adjust hover color to match UI */
}


.editBump-settingTemplateIcon-button {
    border-radius: 8px;
    padding: 8px;
    background-color: #272727;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;

}

.editBump-settingTemplateIcon-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.previewBump-button {
    border-radius: 8px;
    padding: 15px 20px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.previewBump-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */

}

.button-link-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* Gap between the button and the link */
    margin-left: 30px;
}

.previewBump-button:disabled {
    cursor:default;
    opacity: 0.6; /* Set opacity to 0.6 for disabled state */
}

.settingTemplateIcon {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
}


.editBump-section-description {
    resize: vertical;
    min-height: 300px;
    max-height: 800px;
    overflow: auto;
}

.editBump-section-description::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.editBump-section-description::-webkit-scrollbar-track {
    background: #2c2c2c; /* Background color of the scrollbar track */
    border-radius: 5px; /* Rounded corners for the track */
}

.editBump-section-description::-webkit-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.5); /* Dark gray with reduced opacity */
    border-radius: 5px; /* Rounded corners for the thumb */
}

.editBump-section-description::-webkit-scrollbar-thumb:hover {
    background-color: rgba(119, 119, 119, 0.5); /* Lighter gray with reduced opacity on hover */
}



.editBump-input-character-count,
.editBump-textarea-character-count {
    font-size: 12px;
    color: #a9a9a9;
    text-align: right;
    margin-top: 5px;
}



.editBump-thumbnail-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.editBump-thumbnail-upload-area,
.editBump-thumbnail-current {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    aspect-ratio: 16 / 9;
    background-color: #2a2a2a;
    border: 2px dashed #525252;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.editBump-thumbnail-upload-area:hover {
    background-color: #3e3e3e;
    cursor: pointer;
}

.editBump-upload-icon {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    opacity: 0.7;
}

.editBump-thumbnail-current img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.editBump-thumbnail-description {
    font-size: 14px;
    color: #a9a9a9;
    margin-bottom: 15px;
    text-align: left;
}

.editBump-thumbnail-description a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.editBump-section-description-help-text {
    font-size: 14px;
    color: #a9a9a9;
    margin-bottom: 15px;
    text-align: left;
}

.editBump-section-description-help-text a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}


.editBump-description-label-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}



/* TAGS SECTION */
/* TAGS SECTION */
/* TAGS SECTION */
/* TAGS SECTION */


.editBump-tags-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
    background-color: #1d1d1d;
    border: 0.5px solid #3e3e3e;
    position: relative;
}


.editBump-tag {
    display: flex;
    align-items: center;
    background-color: #3e3e3e;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    transition: background-color 0.3s ease;
    position: relative;
}

.editBump-tag:hover {
    background-color: #0e0e0e;
    cursor: pointer;
}

.editBump-tag-close {
    margin-left: 8px;
    cursor: pointer;
    padding-left: 14px; /* Add padding to separate from text */
}

.editBump-tag-input {
    background-color: #3e3e3e;
    color: #ffffff;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    width: 100%;
}

.editBump-tag-input:focus {
    outline: none;
    background-color: #0e0e0e;
}

.editBump-clear-tags-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.editBump-clear-tags-button:hover {
    background-color: #808080; /* Light grey background on hover */
}



.editBump-tags-description {
    font-size: 14px;
    color: #a9a9a9;
    margin-bottom: 15px;
    text-align: left;
}

.editBump-tags-description a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}


/* END EDIT SECTION CONTENT */
/* END EDIT SECTION CONTENT */
/* END EDIT SECTION CONTENT */
/* END EDIT SECTION CONTENT */
/* END EDIT SECTION CONTENT */


/* CONTENT DETAILS SECTION */
/* CONTENT DETAILS SECTION */
/* CONTENT DETAILS SECTION */
/* CONTENT DETAILS SECTION */
/* CONTENT DETAILS SECTION */
/* CONTENT DETAILS SECTION */
.editBump-section-sidebar {
    background-color: #161616;
    border-radius: 0px 0px 5px 0px;
    padding: 20px;
    border-left: 0.5px solid #3e3e3e;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.1); 
    width: 100%;
}

.editBump-section-video {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editBump-section-thumbnail img {
    max-width: 100%;
    border-radius: 8px 8px 0 0;
}

.editBump-section-videopreview {
    width: 100%;
    height: auto;
    max-height: 600px;
}
.bumps-upload-video-preview {
    width: 100%;
    height: auto;
    max-height: 600px;
    border-radius: 5px 5px 0px 0px;
}

.editBump-section-info {
    color: #ffffff;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1d1d1d;
    border: 0.5px solid #3e3e3e;
    padding: 20px 0px 20px 0px;
    border-radius: 0px 0px 5px 5px;
}

.editBump-section-filename-container {
    text-align: left;
    margin-left: 20px;
}

.editBump-section-filename-label {
    font-size: 15px;
    color: #a9a9a9;
    margin-bottom: 3px;
    text-align: left;
}

.editBump-section-filename {
    font-size: 18px;
    color: #ffffff;
    text-align: left;
}

.editBump-section-logo-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 7px; /* Adjust the spacing between the elements */
}


.bumpups-white-logo {
    height: 20px;
    width: auto;
    opacity: 0.7;
}

.editBump-section-bumping-time {
    font-size: 16px;
    color: #aaaaaa;
    text-align: left;

}

.editBump-section-quality-container {
    text-align: right;
    margin-right: 20px;
}

.editBump-section-quality {
    font-size: 22px;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
}

.editBump-app-distribution {
    margin-top: 30px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 5px;
    text-align: left;
}

.editBump-app-distribution h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.editBump-app-distribution p {
    font-size: 14px;
    color: #a9a9a9;
    margin-bottom: 20px;
    text-align: left;
}

.editBump-app-distribution-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    border-radius: 8px;
    padding: 5px 15px;
    margin-bottom: 10px;
    text-align: left;
}

.editBump-app-distribution-icon {
    font-size: 20px;
    color: #ffffff;
    margin-right: 10px;
}

.editBump-app-distribution-content {
    flex-grow: 1;
    text-align: left;
    margin-right: 20px;
}

.youtube-distribute-title {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
    color: white;
    font-weight: bold;
}
.youtube-distribute-subititle {
    font-size: 14px;
    color: #a9a9a9;
    text-align: left;
}

.editBump-app-distribution-btn {
    border-radius: 8px;
    padding: 15px 20px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.editBump-app-distribution-btn:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.editBump-app-distribution-btn.disabled {
    opacity: 0.6;
    cursor:default;
}

.isVisibilitySelected {
    border: 1px solid white;
}


.editBump-app-distribution-buttons {
    display: flex;
    gap: 10px; /* Adjust the spacing as needed */
}

.editBump-section-visibility {
    padding: 15px;
}

.youtube-logo {
    width: 70px; /* Adjust as needed */
    height: 70px; /* Adjust as needed */
}

.editBump-app-distribution-icon {
    margin-right: 10px; /* Adjust as needed for spacing */
}

/* SCHEDULE ACCORDION */
.editBump-accordion {
    border-bottom: 1px solid #3e3e3e;
    margin-bottom: 10px;
}

.editBump-accordion:last-child {
    border-bottom: none;
}

.editBump-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
}

.editBump-accordion-content {
    text-align: left;
    border: 1px solid #3e3e3e;
    border-radius: 5px;
    padding: 25px;
}

.visibilityRadio {
    width: 20px; /* adjust as needed */
    height: 20px; /* adjust as needed */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #ffffff;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

.visibilityRadio:checked::before {
    content: '';
    display: block;
    width: 12px; /* adjust as needed */
    height: 12px; /* adjust as needed */
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.editBump-accordion-description {
    font-size: 16px;
    color: #a9a9a9;
    margin-bottom: 30px;
    margin-top: 10px;
    text-align: left;
}

.editBump-visibility-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.editBump-visibility-option {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    color: #ffffff;
}

.editBump-visibility-option input[type="radio"] {
    display: inline-block;
}

.editBump-visibility-option .editBump-visibility-text {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    flex-grow: 1;
    align-items: flex-start;
}

.editBump-visibility-title-wrapper {
    display: flex;
    align-items: center;
}

.editBump-visibility-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
}

.editBump-visibility-description {
    font-size: 14px;
    color: #a9a9a9;
}

/* SCHEDULE SELECTION */
.editBump-schedule-wrapper {
    width: 100%;
    max-width: 400px;
}

.editBump-schedule-date-time {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    gap: 32px; 
}

.editBump-timezone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.editBump-timezone-select {
    padding: 10px;
    background-color: #1d1d1d;
    color: #ffffff;
    border: 1px solid #3e3e3e;
    border-radius: 5px;
    font-size: 18px;
    outline: none; /* Removes blue border on focus */
}

.editBump-timezone-select:focus {
    border-color: #161616; /* Change the border color on focus if needed */
}

.editBump-schedule-summary {
    margin-top: 18px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #a9a9a9;
    text-align: left;
}

.editBump-date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    min-width: 150px;
}

.editBump-date-label {
    font-size: 14px;
    color: #a9a9a9;
    margin-bottom: 2px;
}

.editBump-schedule-dropdown {
    /* Remove default browser arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    /* Existing styles */
    width: 100%;
    padding: 10px;
    background-color: #1d1d1d;
    color: #ffffff;
    border: 1px solid #3e3e3e;
    border-radius: 5px;
    font-size: 18px;
    outline: none;
    
    /* Custom arrow as background image (white arrow SVG in this example) */
    background-image: url("data:image/svg+xml,%3Csvg%20viewBox='0%200%20292.4%20292.4'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20fill='%23fff'%20d='M287%2069.2l-14.3-14.3c-3.6-3.6-9.4-3.6-13%200L146.2%20168.5%2032.7%2055%2018.4%2069.2l127.8%20127.8c3.6%203.6%209.4%203.6%2013%200L287%2082.2c3.6-3.6%203.6-9.4%200-13z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center; /* Move arrow left or right as needed */
    background-size: 14px; /* Adjust arrow size as needed */
    padding-right: 35px; /* Ensure space on the right so text doesn't overlap arrow */
  }
  

.editBump-schedule-dropdown:focus {
    border-color: #161616;
}

.editBump-timezone-label {
    margin-top: 3px;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
}

.editBump-schedule-dropdown.invalid {
    border-color: red;
}

.invalid-date-error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

/* POP-UP STYLING */
.editBump-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.editBump-popup-content {
    background-color: #0e0e0e;
    border-radius: 5px;
    padding: 26px;
    width: 80%;
    max-width: 600px;
    position: relative;
    border: 1px solid #3e3e3e;
}

.editBump-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editBump-popup-title {
    font-size: 18px;
    font-weight: bold;
}

.editBump-close-btn {
    background: transparent;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}

.editBump-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.editBump-popup-inner-content-preview {
    margin-top: 20px;
    white-space: pre-wrap;  /* Preserve whitespace and line breaks */
    text-align: left;       /* Align text to the left */
    background-color: #1d1d1d;
    padding: 20px;
    color: #ffffff;
    border: 0.5px solid #3e3e3e;
    font-size: 16px;
    max-height: 600px;
    overflow-y: auto;      /* Enable vertical scrolling */
}

.aibump-linkified {
    color: #3da6fe;
    text-decoration: none;
}

.aibump-linkified:hover {
    text-decoration: underline;
}

.aibump-hashtag {
    color: #3da6fe;
}

.aibump-timestamp {
    color: #3da6fe;
}

/* Custom Scrollbar Styling */
.editBump-popup-inner-content-preview::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .editBump-popup-inner-content-preview::-webkit-scrollbar-track {
    background: #2c2c2c; /* Background color of the scrollbar track */
    border-radius: 5px; /* Rounded corners for the track */
  }
  
  .editBump-popup-inner-content-preview::-webkit-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.5); /* Dark gray with reduced opacity */
    border-radius: 5px; /* Rounded corners for the thumb */
  }
  
  .editBump-popup-inner-content-preview::-webkit-scrollbar-thumb:hover {
    background-color: rgba(119, 119, 119, 0.5); /* Lighter gray with reduced opacity on hover */
  }
  

.editBump-invalid-date-error-message {
    margin-bottom: 10px;
    text-align: left;

}


/* END CONTENT DETAILS SECTION */

/* END CONTENT DETAILS SECTION */
/* END CONTENT DETAILS SECTION */
/* END CONTENT DETAILS SECTION */


/* PROGRESS BAR */
/* PROGRESS BAR */
/* PROGRESS BAR */
/* PROGRESS BAR */
/* PROGRESS BAR */
@keyframes pulsate {
    0% {
        transform: scale(1);
        background-color: #ffffff;
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        background-color: #555;
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        background-color: #ffffff;
        opacity: 1;
    }
}

.valueBump-learn-more-link {
    color: #ffffff;      /* Keep the text white */
    text-decoration: none; /* No underline by default */
    cursor: pointer;
  }
  
  .valueBump-learn-more-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  

.valueBump-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 0px;
    position: relative;
    border: 0.5px solid #3e3e3e;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 0px;
}

.valueBump-progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    flex: 1;
}

.valueBump-progress-circle-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.valueBump-progress-circle-wrapper::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 24px);
    height: 2px;
    z-index: 0;
    transform: translateX(12px);
    background-color: transparent; /* Default to transparent */
}

.valueBump-progress-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #555;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}

.valueBump-progress-circle.valueBump-completed {
    background-color: #fff;
}

.valueBump-progress-circle img {
    width: 16px;
    height: 16px;
}

.valueBump-progress-circle.pulsating {
    animation: pulsate 2s infinite; /* Slower animation */
}

.valueBump-progress-label {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
}

.valueBump-completed .valueBump-progress-circle {
    background-color: #fff;
}

.valueBump-completed .valueBump-progress-circle-inner {
    background-color: transparent;
}

.valueBump-completed .valueBump-progress-circle img {
    display: block;
}

.valueBump-progress-line {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 24px);
    height: 2px;
    z-index: 0;
    transform: translateX(12px);
}


.transcript-reprocess-button {
    padding: 6px 12px; /* Adds padding for better button appearance */
    background-color: #f44336; /* Red background to indicate action/reprocessing */
    color: white; /* White text color for contrast */
    border: none; /* Removes default button border */
    border-radius: 4px; /* Rounds the corners of the button */
    cursor: pointer; /* Changes cursor to pointer to indicate clickable action */
    font-size: 14px; /* Font size for the button text */
    font-weight: bold; /* Makes the text bold */
    transition: background-color 0.3s ease; /* Smooth transition for background color changes */
    z-index: 20;
}

.transcript-reprocess-button:hover {
    background-color: #d32f2f; /* Darker red on hover for feedback */
}




/* END PROGRESS BAR */
/* END PROGRESS BAR */
/* END PROGRESS BAR */
/* END PROGRESS BAR */
/* END PROGRESS BAR */
/* END PROGRESS BAR */
/* END PROGRESS BAR */



/* Mobile Styles */
@media (max-width: 768px) {

    .valueBump-progress-bar {
        margin-bottom: 25px;
        border: 0px;
        border-bottom: 0.5px solid #3e3e3e;
    }
    

    .editBump-section-container {
        padding: 10px 25px 25px 25px;
    }

    .valueBump-progress-label {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 15px;
    }


    .editBump-popup-inner-content-preview {
        font-size: 12px;
    }
    

    .editBump-sections-wrapper {
        flex-direction: column;
        border: 0px solid #3e3e3e;

    }

    .editBump-section-content,
    .editBump-section-sidebar {
        padding: 0px;
        border: 0px solid #3e3e3e;

    }

    .editBump-section-content {
        border-bottom: 1px solid #3e3e3e;
        margin-bottom: 30px;

    }


    .editBump-title-button, .previewBump-button
     {
        padding: 10px 14px;
        font-size: 14px;
    }

    .previewBump-button {

        width: 100%;
    }
    .editBump-settingTemplateIcon-button{
        padding: 1px;
        
    }
    
    .editBump-thumbnail-upload-area {
        max-width: 100%;
        max-height: 120px;
    }
    .editBump-section-video {
        flex-direction: column;
        align-items: flex-start;
    }

    .editBump-section-videopreview {
        border: 0.5px solid #3e3e3e;

    }

    .editBump-section-thumbnail img,
    .bumps-upload-video-preview {
        max-height: 300px;
    }

    .editBump-section-info {
        flex-direction: row;
        align-items: flex-start;
        padding: 15px 0;
    }

    .editBump-section-filename-container,
    .editBump-section-quality-container {
        margin: 0 10px;
    }

    .editBump-section-quality-container {
        margin-top: 10px;
    }

    .editBump-app-distribution {
        margin-top: 20px;
        padding: 15px;
        margin-bottom: 20px;
    }

    .editBump-app-distribution-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .editBump-app-distribution-buttons {
        flex-direction: row;
        gap: 10px;
    }

    .editBump-app-distribution-btn {
        padding: 10px 14px;
        font-size: 14px; /* Smaller text */
    }

    .youtube-logo {
        width: 70px;
        height: 70px;
    }

    .editBump-schedule-wrapper {
        max-width: 100%;
    }

    .editBump-popup-content {
        width: 75%;
        max-width: none;
    }

    .editBump-popup-header {
        align-items: flex-start;
    }

    .editBump-popup-footer {
        margin-top: 30px;
        justify-content: left;
        width: 100%;
    }

    .editBump-section-filename-label,
    .editBump-visibility-title,
    .editBump-date-label {
        font-size: 13px; /* Smaller text */
    }

    .editBump-section-filename,
    .editBump-visibility-option {
        font-size: 14px; /* Smaller text */
    }

    .editBump-section-quality,
    .editBump-timezone-label {
        font-size: 18px; /* Smaller text */
    }

    .editBump-app-distribution h3,
    .editBump-popup-title {
        font-size: 18px; /* Smaller text */
    }

    .editBump-app-distribution p,
    .editBump-visibility-description,
    .editBump-schedule-summary {
        font-size: 12px; /* Smaller text */
        text-align: left;
    }

    .editBump-app-distribution-content {
        font-size: 16px; /* Smaller text */
        margin-bottom: 20px;

    }



    .editBump-section-description-help-text {
        font-size: 11px;
    }


    .editBump-schedule-dropdown {
        font-size: 16px;
    }

    .editBump-schedule-date-time {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; /* spacing between fields */
      }
    
      .editBump-date-wrapper {
        flex: 1 1 120px; /* flex-grow, flex-shrink, flex-basis */
        min-width: 120px; /* so each has at least enough room for “September” */
        max-width: none;  /* let them grow if space allows */
      }

}


@media (min-width: 769px) and (max-width: 1280px) {

    .valueBump-progress-bar {
        margin-bottom: 30px;
        border: 0px;
        border-bottom: 1px solid #3e3e3e;
    }

    .editBump-section-container {
        padding: 0px 20px 20px 20px;
    }

    .valueBump-progress-label {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .editBump-sections-wrapper {
        flex-direction: row;
    }

    .editBump-section-content,
    .editBump-section-sidebar {
        padding: 20px;
    }

    .editBump-title-button, .previewBump-button {
        padding: 10px 14px;
        font-size: 14px;
    }

    .previewBump-button {

        width: 100%;
    }

    .editBump-section-title-label,
    .editBump-section-description-label,
    .editBump-section-thumbnail-label,
    .editBump-section-tags-label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .editBump-settingTemplateIcon-button {
        padding: 2px;
    }

    .editBump-thumbnail-upload-area {
        max-width: 50%;
    }

    .editBump-section-video {
        flex-direction: column;
        align-items: flex-start;
    }

    .editBump-section-videopreview {
        border: 1px solid #3e3e3e;
    }

    .editBump-section-thumbnail img,
    .bumps-upload-video-preview {
        max-height: 320px;
    }

    .editBump-section-info {
        flex-direction: row;
        align-items: flex-start;
        padding: 20px 0;
    }

    .editBump-section-filename-container,
    .editBump-section-quality-container {
        margin: 0 15px;
    }

    .editBump-section-quality-container {
        margin-top: 15px;
    }

    .editBump-app-distribution {
        margin-top: 25px;
        padding: 20px;
        margin-bottom: 25px;
    }

    .editBump-app-distribution-item {
        flex-direction: row;
        align-items: flex-start;
        padding: 20px 20px 10px 20px;
    }

    .editBump-app-distribution-buttons {
        flex-direction: row;
        gap: 10px;
    }

    .editBump-app-distribution-btn {
        font-size: 15px;
    }

    .youtube-logo {
        width: 70px;
        height: 70px;
    }

    .editBump-schedule-wrapper {
        max-width: 100%;
    }

    .editBump-popup-header {
        align-items: flex-start;
    }

    .editBump-popup-footer {
        margin-top: 35px;
        justify-content: left;
        width: 100%;
    }

    .editBump-section-filename-label,
    .editBump-visibility-title,
    .editBump-date-label {
        font-size: 14px;
    }

    .editBump-section-filename,
    .editBump-visibility-option {
        font-size: 16px;
    }

    .editBump-section-quality,
    .editBump-timezone-label {
        font-size: 20px;
    }

    .editBump-app-distribution h3,
    .editBump-popup-title {
        font-size: 20px;
    }

    .editBump-app-distribution p,
    .editBump-visibility-description,
    .editBump-schedule-summary {
        font-size: 14px;
        text-align: left;
    }

    .editBump-section-description-help-text {
        font-size: 12px;
    }
    
    .editBump-thumbnail-upload-area,
    .editBump-thumbnail-current {

        max-width: 130px;
    }

    .editBump-upload-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
    }

}



@media (min-width: 1281px) and (max-width: 1800px) {

    .editBump-section-title-label,
    .editBump-section-description-label,
    .editBump-section-thumbnail-label,
    .editBump-section-tags-label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .editBump-title-button {
        padding: 12px 16px;
        font-size: 15px;
    }

    .previewBump-button {
        padding: 12px 16px;
        font-size: 15px;
        width: 110px;
    }

    .editBump-settingTemplateIcon-button{
        padding: 4px;
        
    }

    .editBump-app-distribution-buttons {
        flex-direction: row;
        gap: 15px;
    }

    .editBump-app-distribution-btn {
        font-size: 14px;
    }

    .editBump-schedule-wrapper {
        max-width: 100%;
    }

    .editBump-popup-header {
        align-items: flex-start;
    }

    .editBump-popup-footer {
        margin-top: 40px;
        justify-content: left;
        width: 100%;
    }

    .editBump-section-filename-label,
    .editBump-visibility-title,
    .editBump-date-label {
        font-size: 16px;
    }

    .editBump-section-filename,
    .editBump-visibility-option {
        font-size: 18px;
    }

    .editBump-section-quality,
    .editBump-timezone-label {
        font-size: 22px;
    }

    .editBump-app-distribution h3,
    .editBump-popup-title {
        font-size: 22px;
    }

    .editBump-app-distribution p,
    .editBump-visibility-description,
    .editBump-schedule-summary {
        font-size: 16px;
        text-align: left;
    }
}

/* Parent container */
.studio-meta-container {
    display: flex;
    flex-direction: column;
    background-color: #161616;
  }
  
  /* Top Navigation Bar */
  .studio-top-nav-bar {
    display: flex;
    background-color: #161616;
    padding: 1rem;
    border-bottom: 0.5px solid #3e3e3e;
    justify-content: center;
    align-items: center;
  }
  
  /* Top Navigation Bar Title */
  .studio-top-nav-title {
    margin-right: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
  }
  
  /* Individual Nav Items */
  .studio-nav-item {
    margin-right: 2rem;
    text-decoration: none;
    color: #aaa;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s;
  }
  
  .studio-nav-item:hover {
    color: #fff;
  }
  
  /* Active tab styling */
  .studio-nav-item.active {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  
  /* Main content area */
  .studio-content {
    flex: 1;
    padding: 2rem;
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /* Responsive styling: Mobile Devices (up to 768px) */
  @media (max-width: 768px) {
    .studio-top-nav-bar {
      padding: 0.8rem;
    }
    
    .studio-top-nav-title {
      margin-right: 1.5rem;
      font-size: 1.1rem;
    }
    
    .studio-nav-item {
      margin-right: 1.5rem;
      font-size: 15px;
    }
    
    .studio-content {
      padding: 1.5rem;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
  /* Responsive styling: Tablet Devices (769px to 1280px) */
  @media (min-width: 769px) and (max-width: 1280px) {
    .studio-top-nav-bar {
      padding: 0.8rem;
    }
    
    .studio-top-nav-title {
      margin-right: 1.5rem;
      font-size: 1.1rem;
    }
    
    .studio-nav-item {
      margin-right: 1.5rem;
      font-size: 15px;
    }
    
    .studio-content {
      padding: 1.5rem;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
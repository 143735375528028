/* Define color variables for consistency */
:root {
  --background-color: #161616;
  --text-color: #FFFFFF;
  --light-text-color: #B3B3B3;
  --accent-color: #3da86b;
  --input-background-color: #2A2A2A;
  --input-border-color: #333333;
  --border-color: #282828;
}

/* Base Styles for Large Screens (Above 1280px) */
.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  padding: 20px;
  margin-top: 40px;
}

/* Styling for the Sign-In Title */
.signin-title {
  font-size: 26px; /* Adjust as needed */
  color: var(--text-color);
  margin-bottom: 20px; /* Space below the title */
  text-align: center; /* Center the text */
}

.signup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.signup-content {
  display: flex;
  justify-content: flex-start; /* Maintains row layout */
  align-items: center;
  gap: 20px; /* Space between sections */
  max-width: 1100px;
  width: 100%;
}

.signup-left {
  flex: 1;
  color: var(--text-color);
  text-align: left;
}

.signup-left h2 {
  font-size: 42px;
  margin-bottom: 32px;
}

.signup-left-signinsection{
  flex: 1;
  color: var(--text-color);
  text-align: left;
}

.signup-left-signinsection h2 {
  font-size: 42px;
  margin-bottom: 32px;
}


.feature-list {
  list-style: none; /* Remove default list styling */
  padding-left: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--text-color);
}

.checkmark-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px;
  margin-right: 10px; /* Space between icon and text */
}

/* Right Section */
.signup-form-container {
  flex: 0 0 400px;
  background-color: var(--background-color);
  padding: 40px;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  text-align: center;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #4184f3; /* Updated color */
  color: #fff; /* Text color for better contrast */
  border: none;
  border-radius: 4px;
  padding: 10px 0;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.google-button:hover {
  background-color: #357ae8; /* Darker shade on hover */
}

.google-logo-container {
  background-color: #fff;
  border-radius: 4px; /* Rounded corners */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.google-logo-left {
  width: 24px; /* Increased size */
  height: 24px;
}

.google-signup-text {
  flex: 1;
  text-align: center;
  font-size: 20px; /* Increased font size */
  font-weight: bold; /* Made text bold */
  color: #fff; /* Text color updated to white */
}

.divider-signpage {
  position: relative;
  margin: 40px 0;
  height: 1px;
  background-color: var(--border-color);
  width: 100%; /* Make divider span full width */
}

.divider-text-signpage {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color);
  padding: 0 10px;
  color: var(--light-text-color);
}

.input-group {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}

.email-label {
  display: block;
  margin-bottom: 12px;
  color: var(--text-color);
}

.email-input {
  width: 92%;
  padding: 12px;
  background-color: var(--input-background-color);
  color: var(--text-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
}

.continue-button {
  width: 100%;
  padding: 14px;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 22px; /* Increased font size */
}

.continue-button:hover {
  background-color: #35a060;
}

.terms-text {
  color: var(--light-text-color);
  font-size: 15px; /* Increased font size */
  text-align: left; /* Aligned text to the left */
}

.highlight-link {
  color: var(--text-color);
  text-decoration: underline;
}

.email-validation-msg {
  color: #FF5733;
  font-size: 14px;
  text-align: left;
}

/* New Trusted Text Styling */
.trusted-text {
  margin-top: 80px; /* Space above the text */
  margin-bottom: 20px; /* Space below the text */
  font-size: 20px; /* Adjust font size as needed */
  color: var(--text-color);
  text-align: center;
}

/* SocialProfileCarousel Styling (Optional) */
.social-profile-carousel {
  margin-top: 20px; /* Space above the carousel */
  width: 100%;
  max-width: 1100px;
}

.login-text {
  color: var(--text-color);

}

/* Responsive Design */

/* Small Screens: 768px and below */
@media (max-width: 768px) {

  .signup-wrapper {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .signup-content {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  
  .signup-left h2 {
    font-size: 30px; /* Adjusted for smaller screens */
    text-align: center;
  }

  .feature-list {
    display: none;
  }

  .signup-left,
  .signup-form-container {
    width: 100%;
  }

  .signup-left {
    margin-bottom: 0px;
  }

  .signup-form-container {
    padding: 20px;
    border: 0px solid var(--input-border-color);

  }

  .google-button {
    padding: 8px 0;
    margin-bottom: 18px;
  }

  .google-logo-container {
    margin-left: 5px;
  }

  .google-signup-text {
    font-size: 18px;
  }

  .divider-signpage {
    margin: 30px 0;
  }

  .divider-text-signpage {
    top: -8px;
    padding: 0 8px;
    font-size: 14px;
  }

  .input-group {
    width: 100%;
    margin-bottom: 12px;
  }

  .email-label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .email-input {
    padding: 10px;
  }

  .continue-button {
    padding: 12px;
    font-size: 20px;
  }

  .terms-text {
    font-size: 14px;
  }

  .trusted-text {
    margin-top: -20px; /* Adjust space for smaller screens */
    font-size: 18px; /* Slightly smaller font size */
  }

  .signin-title {
    font-size: 24px; /* Smaller font on mobile */
    margin-bottom: 15px;
  }

  .signup-left-signinsection{
display: none;
  }

}


/* Medium Screens: 769px to 1280px */
@media (min-width: 769px) and (max-width: 1280px) {
  .signup-page {
    padding: 25px;
    margin-top: 30px;
  }

  .signin-title {
    font-size: 26px;
    margin-bottom: 18px;
  }

  .signup-content {
    gap: 15px;
    max-width: 900px;
  }

  .signup-left h2 {
    font-size: 32px;
    margin-bottom: 24px;
  }

  .feature-list li {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .checkmark-icon {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }

  .signup-form-container {
    flex: 0 0 350px;
    padding: 30px;
  }

  .google-button {
    padding: 8px 0;
    margin-bottom: 18px;
  }

  .google-logo-left {
    width: 20px;
    height: 20px;
  }

  .google-signup-text {
    font-size: 18px;
  }

  .divider-signpage {
    margin: 30px 0;
  }

  .divider-text-signpage {
    top: -8px;
    padding: 0 8px;
    font-size: 14px;
  }

  .email-input {
    width: 90%;
    padding: 10px;
  }

  .continue-button {
    padding: 12px;
    font-size: 20px;
  }

  .terms-text {
    font-size: 14px;
  }

  .trusted-text {
    margin-top: 60px;
    font-size: 18px;
  }

  .social-profile-carousel {
    margin-top: 15px;
  }
}

/* Field Container */
.comment-settings-field {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

/* Inputs and Textareas */
.comment-settings-field input[type="number"],
.comment-settings-field input[type="text"],
.comment-settings-field textarea {
  padding: 12px;
  font-size: 1.1rem;
  border: 0.5px solid #3a3a3a;
  border-radius: 5px;
  background-color: #1d1d1d;
  color: #fff;
  outline: none;
}

.comment-settings-field textarea {
  resize: none; /* Prevents resizing */
}

.comment-settings-field input:hover,
.comment-settings-field textarea:hover {
  border-color: white;
}

/* Character Count */
.char-count {
  font-size: 0.8rem;
  color: #ccc;
}

/* Error Styling */
.input-error {
  border: 0.5px solid #FF5555 !important;
}

/* Preview Comment Styles */
.preview-comment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.prevprofile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #0e0e0e;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.preview-text {
  background-color: #0e0e0e;
  border: 1px solid #3e3e3e;
  border-radius: 12px;
  padding: 19px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  text-align: left;
}

/* Timestamp Styling */
.timestamp-time {
  font-weight: bold;
  color: #3da6fe;
  margin-right: 2px;
}

/* Toggle & Save Buttons */
.comment-active-btn {
  border: none;
  background-color: #3AA17E;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  transition: background-color 0.2s, opacity 0.2s;
  margin-right: 2px;
}

.comment-active-btn.inactive {
  opacity: 0.5;
  background-color: #2c2f2f;
}

/* Disabled Form Container */
.form-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Checkbox for Branding Removal */
.styled-checkbox {
  width: 20px;
  height: 20px;
  margin-top: 20px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: #333;
  cursor: pointer;
}

.styled-checkbox:checked {
  background-color: #4add80;
  border: 2px solid #333;
  background-repeat: no-repeat;
  background-position: center;
}


.comment-settings-field-fixtext {
  position: relative; /* to position char-count absolutely inside */
  width: 100%;        /* ensure it takes full width */
  margin: 1rem 0;     /* optional spacing */
}

/* Style the textarea to span full width */
.comment-settings-field-fixtext textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  padding-bottom: 30px; /* extra bottom padding so text won't overlap char-count */
  font-size: 1.1rem;
  border: 0.5px solid #3a3a3a;
  border-radius: 5px;
  background-color: #1d1d1d;
  color: #fff;
  outline: none;
  resize: none; /* remove manual resizing if desired */
}

/* Position the char-count in the bottom-right corner of the container */
.comment-settings-field-fixtext .char-count {
  position: absolute;
  bottom: 12px;
  right: 8px;
  font-size: 0.8rem;
  color: #ccc;
}




.csettings-comment-settings-field-preview {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

/* Hide default number input arrows/spinner */
.csettings-timestamp-input::-webkit-inner-spin-button,
.csettings-timestamp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.csettings-timestamp-input {
  width: 20px;
  text-align: center;
  font-size: 1.1rem;
  padding: 12px;
  border: 0.5px solid #3a3a3a;
  border-radius: 5px;
  background-color: #1d1d1d;
  color: #fff;
  outline: none;
  font-weight: bold;
}

.csettings-timestamp-input:hover {
  border-color: white;
}

.comment-settings-field-auto {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.comment-settings-field-auto input[type="text"] {
  width: 350px; /* Adjust this to your preference (px or %) */
  box-sizing: border-box;
  padding: 12px;
  font-size: 1.1rem;
  border: 0.5px solid #3a3a3a;
  border-radius: 5px;
  background-color: #1d1d1d;
  color: #fff;
  outline: none;
}

.comment-settings-field-auto input[type="text"]:hover {
  border-color: white;
}


/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  .account-settings-container {
    padding: 12px;
    text-align: center;
  }
  .comment-settings-field {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .preview-comment {
    flex-direction: column;
    align-items: center;
  }
  .prevprofile-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .comment-settings-field-auto input[type="text"] {
    width: 100%; /* Adjust this to your preference (px or %) */
  }
}


@media (max-width: 768px) {
  .styled-checkbox {
    display: none;
  }


}
/****************************************************
 * PAGE / CONTAINER
 * Limits total width to 1200px
 ****************************************************/
 .case-study-container {
  max-width: 1200px;  /* never wider than 1200px */
  margin: 0 auto;     /* center horizontally */
  background-color: #161616;
  padding: 4rem 1vw;  /* outer spacing (adjust as needed) */
  box-sizing: border-box;
}

/****************************************************
 * CARD
 * Always 700px tall, 2 columns side by side on desktop
 ****************************************************/
.case-study-card {
  display: flex;
  flex-direction: row;  /* side-by-side on desktop */
  width: 100%;
  height: 700px;        /* fixed total height */
  border-radius: 15px;
  background-color: #262626;
  overflow: hidden;     /* clip children if they overflow */
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

/****************************************************
 * COLUMNS (each 50% width on desktop)
 ****************************************************/
.case-study-right {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 2rem;

  /* Fill full height on desktop */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* center vertically on desktop */
}

.case-study-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-study-img:hover {
  transform: scale(1.03); /* Slightly enlarge the image on hover */
}


/* Left side placeholder styling */
.case-study-left {
  align-items: center;
  text-align: center;
}

/* Right side text styling */
.case-study-right {
  text-align: left;
}

/****************************************************
 * TEXT EXAMPLES
 ****************************************************/
.case-study-right h2 {
  margin: 0 0 1rem;
  font-size: 1.8rem;
  line-height: 1.3;
  color:#fff;
}

.case-study-right p {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color:#fff;
}

/* Example: stats headings inside .stats container */
.stats h3 {
  margin: 1rem 0 0.5rem;
  color:#fff;
}
.cs-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #141414;
  color: #4add80;
  border: none;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 4px;
  text-decoration: none;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 230px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cs-button:hover {
  background-color: #1a1a1a;
  transform: translateY(-2px);
}


.metrics{
  color:#4add80;
}

.cs-subtitle {
  display: block;           /* Ensures it starts on a new line */
  font-size: 1.2rem;        /* Slightly smaller than the main title */
  font-weight: 400;         /* Normal font weight */
  color: #a0a0a0;           /* Lighter gray for contrast */
  margin-top: 0.5rem;       /* Space above the subtitle */
  line-height: 1.4;         /* Improved readability */
}
.cs-arrow {
  display: inline-block;
  margin-left: 0.5rem; /* adds space between the text and the arrow */
  font-size: 1rem;
  transition: transform 0.2s ease;
}


/****************************************************
 * RESPONSIVE
 * On mobile (max-width: 768px), stack vertically,
 * top half = left column (350px), bottom half = right column (350px)
 ****************************************************/
@media (max-width: 768px) {
  .case-study-card {
    flex-direction: column; 
    height: auto;      /* allow content to dictate height */
  }
  
  .case-study-right {
    flex: 0 0 auto;   /* allow the fixed height below to take precedence */
    width: 100%;
    padding: 2rem;    /* maintain your desired padding */
    justify-content: flex-start;  /* align content at the top if needed */
    align-items: flex-start;      /* left-align items */
  }

  .case-study-left {
    height: 350px;    /* half of 700px */
  }



}

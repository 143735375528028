
  /* Header for the section (outside container) */
  .setting-header {
    font-size: 18px;
    font-weight: 500;
    color: #b2b2b2;
    text-align: left;
    margin-bottom: 15px;
  }
  

  /* Right-side field container */
  .settings-field {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Timestamp button styles */
  .timestamp-button {
    padding: 12px 20px;
    font-size: 0.95em;
    border: none;
    background-color: #2c2f2f;  /* base color */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, opacity 0.3s;
    margin: 0 2px;
    border-radius: 5px;
    outline: none;
    color: white;
    opacity: 0.6;
  }
  
  .timestamp-button:hover {
    background-color: #2c2f2f;
    opacity: 0.8;
    transform: translateY(-2px);
  }
  
  .timestamp-button.selected {
    background-color: #3a3d3d; /* slightly lighter than the base */
    opacity: 1;
    border: 1px solid #555;  /* subtle accent border */
  }
  
/****************************************************
 *  HERO SECTION
 ****************************************************/
 .hero-section {
  background-color: #161616;
  /* Switch from max-height to min-height so it can expand if needed */
  min-height: 620px;
  display: flex;
  /* Center content vertically and horizontally in the hero section */
  align-items: center;
  justify-content: center;
  padding: 4rem 1vw;
  box-sizing: border-box;
  z-index: 1;
}

/****************************************************
 *  HERO CONTENT WRAPPER (GRID LAYOUT)
 ****************************************************/
.hero-content-wrapper {
  display: grid;
  grid-template-columns: 1.3fr 1fr; 
  gap: 1rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  /* Ensures each grid item (left and right columns) is centered vertically */
  align-items: center;
}

/****************************************************
 *  LEFT COLUMN
 ****************************************************/
.hero-left-col {
  display: flex;
  flex-direction: column;
  /* This will vertically center the text within its column */
  justify-content: center;
  text-align: left;
}

.hero-title {
  font-size: 4.3rem;
  font-weight: 700;
  line-height: 1.05;
  margin: 0;
  color: #fff;
}

.hero-subtitle {
  font-size: 1.15rem;
  line-height: 1.6;
  color: #bbb;
  margin-bottom: 1rem;
  max-width: 80%;
}

.button-wire-container {
  position: relative;
  display: inline-block;
  margin-top: 0.5rem;
}

/****************************************************
 *  HERO BUTTON
 ****************************************************/
.hero-cta {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  background-color: #3da86b;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-decoration: none;
}

.hero-cta:hover {
  background-color: #333;
}

.arrow-icon {
  font-size: 1.2rem;
  line-height: 1;
}

/****************************************************
 *  RIGHT COLUMN
 ****************************************************/
.hero-right-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-hover-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/****************************************************
 *  RESPONSIVE (MOBILE)
 ****************************************************/
 @media (max-width: 768px) {
  .hero-section {
    padding: 2rem 1rem;
    min-height: auto; 
  }

  .hero-content-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  /* Center align text and button on mobile */
  .hero-left-col {
    text-align: center;
  }

  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    text-align: center;
    max-width: 100%;
  }

  .button-wire-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-hover-image {
    width: 80%; /* Increase image width on mobile */
    transition: none;
    margin: 0 auto; /* center the image */
  }
}

@media (min-width: 769px) and (max-width: 1280px) {
  .hero-title {
    font-size: 3rem;
  }
  
  .hero-content-wrapper {
    gap: 1rem;
  }
  
  .hero-section {
    padding: 4rem 3vw;
  }
}

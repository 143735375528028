
.description-wrapper {
  width: 100%;
}

/* Updated textarea styling */
.description-textarea {
  box-sizing: border-box; /* ensures padding is included in width */
  width: 100%;
  min-height: 400px;
  max-height: 600px; 
  padding: 12px;
  font-size: 1.1rem;
  border: 0.5px solid #3a3a3a;
  border-radius: 5px;
  background-color: #1d1d1d;
  color: #fff;
  outline: none;
  resize: vertical;
}

.textarea-character-count {
  text-align: right;
  font-size: 14px;
  color: #999;
}

/* Updated class name and spacing between description and buttons */
.description-button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Rename the slider classes from .switch / .slider to .description-slider / .description-slider-track */

.description-slider {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.description-slider input {
  opacity: 0;
  width: 0;
  height: 0;
}

.description-slider-track {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 26px;
}

.description-slider-track:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.description-slider input:checked + .description-slider-track {
  background-color: #4add80;
}

.description-slider input:checked + .description-slider-track:before {
  transform: translateX(26px);
}


@media (max-width: 768px) {

.description-button-group {
  flex-direction: column;
  align-items: flex-start; /* Optional: aligns items to the start */
  gap: 15px;
}


}
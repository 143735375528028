/* src/components/ListViewWorkspace/listviewWorkspace.css */

/* Container */
.listworkspace-video-container {
    display: flex;
    flex-direction: column;
    background-color: #161616;
    margin-bottom: 60px;
}

/* Header Row */
.listworkspace-header-row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr; /* Updated to include Duration */
    gap: 16px;
    padding: 8px 24px;
    align-items: center;
    height: 60px; /* Increased height for better alignment */
    background-color: #161616;
    color: #fff;
    font-weight: bold;
    border-bottom: 0.5px solid #333;
}

/* Header Items */
.listworkspace-header-item {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    color: #ccc;
}

/* Create Workspace Button Container */
.create-workspace-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Create Workspace Button */
.create-workspace-button {
    display: flex;
    align-items: center;
    padding: 13px 16px;
    background-color: #1e1e1e; /* Adjust as per your platform's color scheme */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    color: #ffff; /* Updated text color */
    font-weight: bold; /* Make the text bold */
    transition: background-color 0.3s ease;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.1); /* More pronounced shadow on hover */
    justify-content: center;
    max-height: 50px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);

}

.create-workspace-button:hover {
    background-color: #303030; /* Adjust as per your platform's color scheme */
}

.create-workspace-button:disabled {
    background-color: #555555;
    color: #999999;
    cursor: not-allowed;
    box-shadow: none;
}

/* Workspace Add Button */
/* Workspace Add Button */
.workspace-add-button {
    background: #1e1e1e;
    border: none;
    cursor: pointer;
    padding: 6px;
    margin-left: 8px; /* Adjust spacing as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    border: 0.5px solid rgba(255, 255, 255, 0.2);

}

.workspace-add-button:hover {
    background-color: #303030;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: translateY(-2px);
}

.workspace-add-icon {
    width: 24px;
    height: 24px;
}

/* Disabled State */
.workspace-add-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.workspace-add-button:disabled:hover {
    background-color: #1e1e1e; /* Keep background color the same when disabled */
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    transform: none;
}



/* Video Row */
.listworkspace-video-row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr; /* Updated to include Duration */
    gap: 16px;
    padding: 8px 24px;
    align-items: center;
    border-bottom: 0.5px solid #333;
    height: 100px;
    color: #ccc;
    font-size: 14px;
    transition: background-color 0.3s ease;
    position: relative;
}

.listworkspace-video-row:hover {
    background-color: #0e0e0e; /* Slightly darker background color on hover */
}

/* Status Column */
.listworkspace-video-status {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    color: #ccc;
}

/* Duration Column */
.listworkspace-video-duration {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    color: #ccc;
}

/* Header and Video Items */
.listworkspace-header-item,
.listworkspace-video-duration,
.listworkspace-video-meta {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    color: #ccc;
}

/* Video Item */
.listworkspace-video-item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    overflow: hidden;
    margin-right: 16px;
}

/* Thumbnail */
.listworkspace-thumbnail {
    width: 160px;
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
    transition: none;
    cursor: pointer;
}

/* Video Details */
.listworkspace-title-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    position: relative;
}

.listworkspace-video-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #fff;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.clickable-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.listworkspace-description-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    min-height: 37px;
    margin-top: 10px;
}

.listworkspace-video-description {
    font-size: 16px;
    margin: 0;
    color: #aaa;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

/* Distribution and Quality Icons */
.listworkspace-video-distribution,
.listworkspace-video-meta {
    display: flex;
    align-items: center;
    justify-content: left;
}

.utubedistribution-icon-active,
.utubedistribution-icon,
.workspace-logo {
    width: 24px; /* Adjust size as needed */
    height: auto;
    opacity: 0.8;
}

.listworkspace-workspace-logo {
    width: 34px; /* Adjust size as needed */
    height: auto;
    opacity: 0.8;
}

/* View Video Button */
.view-video-button {
    cursor: pointer;
    border-radius: 5px;
    background-color: #161616;
    color: white;
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    border: 0.5px solid #3e3e3e;
    right: 0;
    top: 0;
}

.view-video-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}

.view-video-icon {
    width: 30px;
    height: auto;
}

/* No Content Wrapper */
.no-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-content-image {
    margin-top: 150px;
    width: 350px;
    height: auto;
    border-radius: 12px;
    margin-bottom: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    opacity: 0.9;
}

.no-content-message {
    font-weight: bold;
    color: #ccc;
    text-align: center;
    font-size: 22px;
    margin-bottom: 30px;
}

/* Pagination controls */
.listworkspace-pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.listworkspace-pagination-info {
  color: #fff;
  font-size: 18px;
  margin-right: 16px;
}

.listworkspace-pagination-button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 8px;
  margin: 0 4px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.listworkspace-pagination-button:disabled {
  color: #555;
  opacity: 0.5;
  cursor: default;
}

.listworkspace-pagination-button:hover:not(:disabled) {
  color: #ccc;
}

.listworkspace-arrow {
  border: solid #fff;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 3px;
}

.listworkspace-outside-arrow {
  border: solid #fff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
}

/* Arrow pointing left */
.listworkspace-arrow.listworkspace-left,
.listworkspace-outside-arrow.listworkspace-double-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/* Arrow pointing right */
.listworkspace-arrow.listworkspace-right,
.listworkspace-outside-arrow.listworkspace-double-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.draft-badge {
    display: flex;
    align-items: center;
    background-color: #4a4a4a; /* Dark background for the badge */
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    gap: 5px; /* Space between icon and text */
    margin-left: 6px; /* Space between input and badge */

  }

  .error-badge {
    display: flex;
    align-items: center;
    background-color: #f66767; /* Dark background for the badge */
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    gap: 5px; /* Space between icon and text */
    margin-left: 6px; /* Space between input and badge */
    opacity: 0.7;
  }

  
  .draft-icon {
    width: 16px;
    height: 16px;
  }
  
  .draft-text {
    font-weight: 700;
  }

  .listwork-active-badge {
    display: flex;
    align-items: center;
    background-color: rgba(74, 221, 128, 0.15); /* Subtle green background */
    color: #4add80; /* Green accent color */
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 14px;
    white-space: nowrap; /* Prevents the text from wrapping */
    gap: 5px; /* Space between icon and text */
    margin-left: 6px; /* Space between input and badge */
  }
  
  .active-icon-workspace {
    width: 18px;
    height: 18px;
  }
  
  .active-text {
    font-weight: bold;
  }
  

/* Mobile Optimization */
/* Mobile Optimization */
@media (max-width: 768px) {

    .listworkspace-video-container {
        margin-bottom: 100px;
    }

    /* Only show the Workspaces column on mobile */
    .listworkspace-header-row {
        grid-template-columns: 1fr 1fr; /* Only 1 column for Workspaces */
        padding: 8px;

    }

    .listworkspace-header-item {
        text-align: left;
    }

    .create-workspace-button-container {
        justify-content: center;
    }

    .create-workspace-button {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }

    .listworkspace-video-title {
        font-size: 12px; /* Smaller font size for mobile */
    }

    /* Align the title and button in a row */
    .listworkspace-title-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: left;
        width: 100%; /* Ensure the title and button take full width */
    }

    .listworkspace-video-description {
        font-size: 9px; /* Smaller font size for mobile */
    }

    .view-video-button {
        font-size: 12px; /* Smaller font size for mobile */
        padding: 4px 8px; /* Adjust padding for mobile */
    }

    .view-video-icon {
        width: 20px; /* Smaller icon size for mobile */
    }

    .listworkspace-video-row {
        grid-template-columns: 1fr; /* Only show Workspaces column for video rows on mobile */
        position: relative; /* Add relative position to the row */
    }

    /* Position the badge in the top-left corner of the thumbnail */
    .draft-badge, .listwork-active-badge {
        position: absolute;
        top: 8px; /* Adjust to your preference */
        left: 8px; /* Adjust to your preference */
        z-index: 1; /* Ensure it's above the thumbnail */
    }

    .listwork-active-badge {
        background-color: #1e1e1e; /* Subtle green background */
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);

    }

    .listworkspace-thumbnail {
        position: relative; /* Ensure the badge is placed relative to the thumbnail */
        width: 160px;
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
    }

    .no-content-image {
        margin-top: 50px;
        width: 250px; /* Adjust width for mobile */
    }

    .workspace-add-button {
        display: none;
      }
}





/* Tablet Optimization */
@media (max-width: 1280px) and (min-width: 769px) {
    .listworkspace-video-container {
        margin-bottom: 100px;
    }

    .listworkspace-header-row {
        grid-template-columns: 2.5fr 1fr 1fr; /* Adjust grid columns for tablet - remove Duration column */
    }

    .create-workspace-button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .listworkspace-video-title {
        font-size: 14px; /* Adjust font size for tablet */
    }

    .listworkspace-video-description {
        font-size: 14px; /* Adjust font size for tablet */
    }

    .view-video-button {
        font-size: 14px; /* Adjust font size for tablet */
        padding: 6px 10px; /* Adjust padding for tablet */
    }

    .view-video-icon {
        width: 25px; /* Adjust icon size for tablet */
    }

    .listworkspace-video-row {
        grid-template-columns: 2.4fr 1fr 1fr; /* Adjust grid columns for tablet - remove Duration column */
    }

    .no-content-image {
        margin-top: 100px;
        width: 300px; /* Adjust width for tablet */
    }
}


/* DASHBOARD LAYOUT */
.bumps-uploaddashboard {
    display: flex;
    background-color: #161616;
    min-height: 100vh;
}

.bumps-dashboard-main {
    display: flex;
    justify-content: center;
    width: 100%;
}

.bumps-dashboard-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    
}

.bumps-dashboard-contentgeneral {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    padding: 0px 20px 20px 20px;
    margin-top: 20px;

}

/* HOSTING FILES INFO */
/* HOSTING FILES INFO */
/* HOSTING FILES INFO */

.bumps-hosting-files-info {
    background-color: #161616; /* Match the website background */
    padding: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
    position: relative; /* Add relative positioning */

}

.bumps-hosting-files-info h2 {
    margin-bottom: 10px;
}



/* UPLOAD SECTION */
/* UPLOAD SECTION */
/* UPLOAD SECTION */

.bumps-upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bumps-file-drop-area {
    position: relative;
    border: 2px dashed #555;
    border-radius: 4px;
    padding: 20px 40px;
    text-align: center;
    background-color: #141414;
    transition: border-color 0.3s ease;
    width: 450px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-circle {
    width: 150px;
    height: 150px;
    background-color: #1e1e1e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.upload-logo {
    width: 50px;
    height: 50px;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.bumps-file-drop-area:hover .upload-logo,
.bumps-file-drop-area.drag-over .upload-logo {
    opacity: 1.0;
}

.upload-instruction {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
}

.upload-subtext {
    color: #888;
    font-size: 14px;
    margin-bottom: 20px;
}

.select-files-button {
    border-radius: 8px;
    padding: 13px 18px;
    background-color: #272727;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* subtle shadow */
    border: 0.5px solid #3e3e3e;
}

.select-files-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Lighter shade on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1); /* slightly more pronounced shadow on hover */
}

.select-files-button .icon {
    width: 19px; /* Adjust the size as needed */
    height: auto;
    margin-right: 8px; /* Space between icon and text */
    vertical-align: middle; /* Align the icon with the text */
    margin-bottom: 2px;
  }
  

.bumps-upload-area:hover .bumps-file-drop-area,
.bumps-file-drop-area.drag-over {
    border-color: #ffffff;
}

/* New conditional CSS */
.bumps-upload-area.disabled:hover .bumps-file-drop-area,
.bumps-upload-area.disabled .bumps-file-drop-area.drag-over {
    border-color: #555; /* or a different color if needed */
    cursor: default; /* Optional: change cursor to indicate it's disabled */
}

.bumps-upload-section:hover {
    opacity: 1.0;
}


/* UPLOAD POPUP */
.bumps-upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 92, 81, 0.04); /* Very light green background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.bumps-upload-popup-content {
    background: #1e1e1e;
    padding: 24px;
    color: white;
    max-width: 600px;
    width: 100%;
    position: relative;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 5px;
    border: 1px solid #3e3e3e;
}

.bumps-upload-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.bumps-prevprofile-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
}

.bumps-upload-close-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 23px;
    cursor: pointer;
}

.bumps-upload-content-wrapper {
    width: 100%;
}

.bumps-upload-video {
    width: 100%;
    height: auto;
    max-height: 400px;
    margin: 12px 0;
    border-radius: 10px;
}

.bumps-upload-info-wrapper {
    background: rgba(22, 195, 145, 0.7); /* Light green background with opacity */
    padding: 20px;
    border-radius: 5px;
}

.bumps-upload-status-wrapper {
    background: rgba(22, 195, 145, 0.9); /* Light green background with opacity */
    padding: 14px; /* Updated padding */
    border-radius: 5px;
    margin-top: 20px;
}

.bumps-upload-status-wrapper.error {
    background: rgba(255, 0, 0, 0.6); /* Light red background with opacity */
    padding: 14px;
    border-radius: 5px;
    margin-top: 20px;
}


.bumps-upload-info {
    text-align: left;
    color: #fff;
    font-weight: 500;
}

.bumps-upload-message {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 10px; /* Add space below the message */
}

.bumps-learn-more-link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}

.bumps-upload-progress {
    margin: 0;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
}

.bumps-upload-status {
    text-align: left;
}

.bumps-divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
}

.bumps-upload-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.utubedistribution-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    opacity: 0.7;
}

.utubedistribution-icon-active {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    opacity: 1;
}

.bumps-buttons-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.bumps-upload-thumbnail-button {
    background: #0e0e0e;
    color: #16c391;
    border: 2px solid #16c391;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
    margin-right: 10px;
    font-size: 16px;
}

.bumps-upload-thumbnail-button:hover {
    background: rgba(22, 195, 145, 0.2);
}

.bumps-upload-thumbnail-button.disabled {
    opacity: 0.3;
    cursor:default;
    background: #0e0e0e; /* Ensure background doesn't change on hover */
    color: #16c391;
    border: 2px solid #16c391;
}

.bumps-bump-button {
    background: #16c391;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
    font-size: 16px;
}

.bumps-bump-button:hover {
    background: #13a378;
}

.bumps-bump-button.disabled {
    opacity: 0.3;
    cursor:default;
}

.bumps-thumbnail-small {
    width: 70px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
}



/* CANCEL UPLOAD POPUP */
/* CANCEL UPLOAD POPUP */
.cancel-upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100; /* Ensure it is above the upload popup */
}

.cancel-upload-popup-content {
    background: #0e0e0e;
    padding: 20px;
    color: white;
    max-width: 250px;
    width: 100%;
    text-align: left;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 5px;
    border: 1px solid #3e3e3e;
    opacity: 1.0;
}

.cancel-upload-title {
    font-weight: bold;
    font-size: 24px;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
}

.cancel-upload-message {
    color: #999;
    font-size: 14px;
    margin-top: 10px; /* Add some margin below the title */
}

.cancel-upload-popup-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.confirm-button, .cancel-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
    font-size: 16px;
    width: 100%;
}

.confirm-button {
    background: #0e0e0e;
    color: #16c391;
    border: 2px solid #16c391;
}

.confirm-button:hover {
    background: rgba(22, 195, 145, 0.2);
}

.cancel-button {
    background: #16c391;
    color: #fff;
    border: none;
}

.cancel-button:hover {
    background: #13a378;
}

/* Loading message styles */
.loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-logo {
    width: 120px; /* Adjust size as needed */
    height: 120px; /* Adjust size as needed */
    margin-bottom: 10px;
}

.loading-text {
    font-weight: bold;
    font-size: 24px;
    color: white;
}


/* JUST UPLOADED POPUP */
.just-uploaded-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.04); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100; /* Ensure it is above the upload popup */
}

.just-uploaded-popup-content {
    background: #0e0e0e;
    padding: 5px;
    color: white;
    max-width: 250px;
    width: 100%;
    text-align: left;
    box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.2); /* More pronounced shadow on hover */
    border-radius: 5px;
    border: 1px solid #3e3e3e;
    opacity: 0.9;
}

.uploading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploading-logo {
    width: 120px; /* Adjust size as needed */
    height: 120px; /* Adjust size as needed */
}

.uploading-text {
    font-weight: bold;
    font-size: 24px;
    color: white;
}


/* Mobile Optimization */
@media (max-width: 768px) {
    .bumps-dashboard-main {
        flex-direction: column;
        margin-top:0px;
    }

    .bumps-dashboard-content-wrapper {
        align-items: center; /* Ensure content is centered */
    }

    .bumps-dashboard-contentgeneral {
        flex-direction: column;
        gap: 0px; /* Adjust gap between elements */
        padding: 20px;
        width: 100%; /* Ensure full width */

    }
    
    .bumps-file-drop-area {
        width: 250px; /* Adjust width for mobile */
        height: 250px; /* Adjust height for mobile */
        padding: 15px 30px;
        border-radius: 0px 0px 5px 5px;

    }

    .upload-circle {
        width: 80px; /* Smaller circle for mobile */
        height: 80px;
        margin-bottom: 15px;
    }

    .upload-logo {
        width: 40px; /* Smaller logo for mobile */
        height: 40px;
    }

    .upload-instruction {
        font-size: 15px; /* Smaller font size for mobile */
        margin-bottom: 8px;
    }

    .upload-subtext {
        font-size: 12px; /* Smaller font size for mobile */
        margin-bottom: 15px;
    }

    .select-files-button {
        font-size: 14px; /* Smaller font size for mobile */
        padding: 10px 15px;
    }


    .bumps-upload-popup {
        width: 90%;
        padding: 20px;
    }

    .bumps-upload-message {
        font-size: 14px;
    }

    .bumps-upload-progress {
        font-size: 16px;
    }

    .bumps-upload-thumbnail-button {
        padding: 10px 15px;
        margin-right: 10px;
        font-size: 14px;
    }
    
}


/* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1280px) {
    .bumps-dashboard-main {
        flex-direction: column;
        margin-top: 10px;
    }
    .bumps-dashboard-contentgeneral {
        flex-direction: column;
        gap: 20px; /* Adjust gap between elements */
        padding: 10px;
        width: 100%; /* Ensure full width */

    }
    
    
    .bumps-file-drop-area {
        width: 400px; /* Adjust width for tablet */
        height: 300px; /* Adjust height for tablet */
        padding: 18px 35px;
    }

    .upload-circle {
        width: 120px; /* Adjust circle size for tablet */
        height: 120px;
        margin-bottom: 18px;
    }

    .upload-logo {
        width: 45px; /* Adjust logo size for tablet */
        height: 45px;
    }

    .upload-instruction {
        font-size: 17px; /* Adjust font size for tablet */
        margin-bottom: 9px;
    }

    .upload-subtext {
        font-size: 13px; /* Adjust font size for tablet */
        margin-bottom: 18px;
    }

    .select-files-button {
        font-size: 15px; /* Adjust font size for tablet */
        padding: 12px 17px;
    }
}

/* Screen between 1281px and 1800px */
@media (min-width: 1281px) and (max-width: 1800px) {
    .bumps-file-drop-area {
        width: 420px; /* Adjust width for this screen range */
        height: 320px; /* Adjust height for this screen range */
        padding: 20px 38px;
    }

    .upload-circle {
        width: 140px; /* Adjust circle size for this screen range */
        height: 140px;
        margin-bottom: 19px;
    }

    .upload-logo {
        width: 48px; /* Adjust logo size for this screen range */
        height: 48px;
    }

    .upload-instruction {
        font-size: 17px; /* Adjust font size for this screen range */
        margin-bottom: 9px;
    }

    .upload-subtext {
        font-size: 13px; /* Adjust font size for this screen range */
        margin-bottom: 18px;
    }

    .select-files-button {
        font-size: 15px; /* Adjust font size for this screen range */
        padding: 12px 18px;
    }
}
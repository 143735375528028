.benefits-section {
  background-color: #161616;
  padding: 1rem 1vw; 
}

/* Wrap the heading in a black rectangle, centered */
.benefits-title-wrapper {
  text-align: center;
}

.benefits-title-wrapper h1 {
  display: inline-block;     /* so the black bg only wraps the text */
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 0.5rem 1rem;
}

/* Grid of 4 benefit cards */
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto; /* center the grid in this container */
}

.benefit-card {
  background-color: #161616;
  border-radius: 6px;
  padding: 2.2rem 2rem;
  text-align: left;
  box-shadow: 0 1px 3px rgba(0,0,0,0.08); 
}

.card-icon {
  margin-bottom: 1rem;
}

.card-icon img {
  width: 32px; /* small icon size, adjust as needed */
  height: auto;
  display: inline-block;
}

/* Card heading */
.benefit-card h4 {
  font-size: 0.95rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #4add80;
}

/* Body text */
.benefit-card p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #bbb;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .benefits-title-wrapper h2 {
    font-size: 1.6rem;
  }

  .benefit-card {
    padding: 1.5rem;
  }

}

/* Parent container */
.set-meta-settings {
  display: flex;
  background-color: #161616;
  min-height: 100vh;
}

/* Top Navigation Bar */
.set-top-nav-bar {
  display: flex;
  background-color: #161616;
  padding: 1rem;
  border-bottom: 0.5px solid #3e3e3e;
  justify-content: center;  /* Centers the nav items */
  align-items: center;
}


/* Top Navigation Bar Title */
.set-top-nav-title {
  margin-right: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

/* Individual Nav Items */
.set-nav-item {
  margin-right: 2rem;
  text-decoration: none;
  color: #aaa;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s;
}

.set-nav-item:hover {
  color: #fff;
}

/* Active tab styling */
.set-nav-item.active {
  color: #fff;
  border-bottom: 2px solid #fff;
}

/* Disabled Tab (API coming soon) */
.set-nav-item.disabled {
  color: #555;
  pointer-events: none;
  border-bottom: none;
}

/* Main settings content area */
.set-settings-content {
  flex: 1;
  padding: 2rem;
  margin: 0 auto;          /* Center the content */
  max-width: 700px;       
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;       /* Hide horizontal scroll */
}

/* Account settings layout */
.set-account-settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.set-account-settings-wrapper h2 {
  margin-bottom: 1rem;
}

/* General CSS Elements */
.setting-header {
  font-size: 18px;
  font-weight: 500;
  color: #b2b2b2;
  text-align: left;
}

  /* Each row with a thin dividing line */
  .settings-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 0.5px solid #2a2a2a;
  }
  
  .settings-row:last-child {
    border-bottom: none;
  }
  
  .settings-label {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: left;
  }
  
  /* Subtitle styling for labels */
  .settings-subtitle {
    font-size: 12px;
    color: #999;
    margin-top: 6px;
  }
  
  /* The content on the right side */
  .settings-field {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }


  .ui-setting-button {
    border: none;
    background-color: #2c2f2f;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .ui-setting-button:hover {
    background-color: #3a3d3d;
  }
  
  .ui-setting-button:disabled {
    opacity: 0.5;
    cursor: default;
  }


  /* Mobile Devices (up to 768px) */
@media (max-width: 768px) {

  /* Slightly reduce padding compared to desktop */
  .set-top-nav-bar {
    padding: 0.8rem;
  }
  
  /* Adjust title spacing and font size */
  .set-top-nav-title {
    margin-right: 1.5rem;
    font-size: 1.1rem;
  }
  
  /* Adjust nav items spacing and font size */
  .set-nav-item {
    margin-right: 1.5rem;
    font-size: 15px;
  }
  
  /* Scale down the main content area a bit */
  .set-settings-content {
    padding: 1.5rem;
    max-width: 90%;
    margin: 0 auto;
  }
  
  /* Adjust gap between settings sections for a more compact layout */
  .set-account-settings-wrapper {
    gap: 1.2rem;
  }
  .set-nav-item.disabled {
    display: none;
  }

  .settings-row {
    flex-direction: column;
    align-items: flex-start; /* Optional: aligns items to the start */
    gap: 15px;
  }

  
}

/* Tablet Devices (769px to 1280px) */
@media (min-width: 769px) and (max-width: 1280px) {
  /* Slightly reduce padding compared to desktop */
  .set-top-nav-bar {
    padding: 0.8rem;
  }
  
  /* Adjust title spacing and font size */
  .set-top-nav-title {
    margin-right: 1.5rem;
    font-size: 1.1rem;
  }
  
  /* Adjust nav items spacing and font size */
  .set-nav-item {
    margin-right: 1.5rem;
    font-size: 15px;
  }
  
  /* Scale down the main content area a bit */
  .set-settings-content {
    padding: 1.5rem;
    max-width: 90%;
    margin: 0 auto;
  }
  
  /* Adjust gap between settings sections for a more compact layout */
  .set-account-settings-wrapper {
    gap: 1.2rem;
  }
}

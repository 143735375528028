/* src/components/OneTimeSection.css */

/* One Time Plan Box Styles */
.onetime-plan-box {
    width: 320px;
    margin: auto;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.1);
    border: 1px solid #3e3e3e;
    display: flex;
    flex-direction: column;
    gap: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #141414;
}

/* Dynamic Plan Classes */
.onetime-plan-box.base-plan {
    --amount-color: #ffbf00;
    --button-color: #ffbf00;
    --button-hover-color: #e0a800;
    --button-active-color: #c69500;
}

.onetime-plan-box.bump-plan {
    --amount-color: #4add80;
    --button-color: #4add80;
    --button-hover-color: #3eb06b;
    --button-active-color: #369f68;
}

.onetime-plan-box:hover {
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.3);
}

/* Title Container */
.onetime-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Title Styles */
.onetime-title {
    margin: 0;
    font-size: 1.8rem;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
}

/* "New" Badge Styles */
.onetime-badge {
    background-color: #2c2f2f;
    color: #fff;
    padding: 6px 12px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.85rem;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
}

/* Price Display */
.onetime-price {
    font-size: 1.9rem; /* Increased font size for prominence */
    font-weight: 700;
    text-align: left;
    color: #ffffff;
}

/* Selected Amount Display */
.onetime-selected-amount {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    color: var(--amount-color);
}

/* Plan Selection Section */
.onetime-plan-selection {
    width: 100%;
}

.onetime-plan-label {
    display: block;
    margin-bottom: 12px;
    font-weight: 600;
    color: #ffffff;
    font-size: 1rem;
    text-align: left;
}

.onetime-plan-options {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}

.onetime-plan-option {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border: 2px solid #3e3e3e;
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    transition: transform 0.2s ease, border-color 0.3s ease, background-color 0.3s ease;
    background-color: #2c2f2f;
}

.onetime-plan-option.selected {
    border-color: var(--button-color);
    background-color: #333333;
}

/* Specific Hover Styles for Each Plan Option */
.onetime-plan-option.base-plan-option:hover {
    border-color: #ffbf00; /* Orange hover */
    transform: scale(1.02);
}

.onetime-plan-option.bump-plan-option:hover {
    border-color: #4add80; /* Green hover */
    transform: scale(1.02);
}

.onetime-plan-logo {
    width: 28px;
    height: 28px;
    margin-top: 5px;
    margin-right: 10px;
    opacity: 0.95;
}

.onetime-plan-info {
    display: flex;
    flex-direction: column;
}

.onetime-plan-info h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #ffffff;
    text-align: left;
}

.onetime-plan-info p {
    margin: 5px 0 0 0;
    font-size: 0.9rem;
    color: #cccccc;
}

/* Amount Selection Section */
.onetime-amount-selection {
    width: 100%;
}

.onetime-amount-label {
    display: block;
    margin-bottom: 18px;
    color: #888888;
    font-size: 16px;
    text-align: left;
}

/* New Styles for Amount Buttons */
.onetime-amount-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.onetime-amount-btn {
    flex: 1 1 calc(33.333% - 10px); /* Three buttons per row with gaps */
    padding: 10px 0;
    background-color: #1e1e1e;
    color: #ffffff;
    border: 2px solid var(--button-color); /* Border color */    
    border-radius: 35px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
    opacity: 0.4;
}

.onetime-amount-btn:hover {
    background-color: #3a3a3a; /* A slightly lighter shade for hover */
    transform: translateY(-2px);
}

.onetime-amount-btn.selected {
    background-color: rgba(var(--button-color-rgb), 0.15); /* Light background when selected */
    color: #ffffff;
    border-color: var(--button-color); /* Dynamic border based on selected plan */
    transform: scale(1.02);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
}


/* Responsive Adjustments for Amount Buttons */
@media only screen and (max-width: 480px) {
    .onetime-amount-btn {
        flex: 1 1 calc(50% - 10px); /* Two buttons per row on small screens */
    }
}

/* Proceed Button */
.onetime-proceed-btn {
    padding: 14px 20px;
    background-color: rgba(var(--button-color-rgb), 0.15); /* Light background */
    color: var(--button-color); /* Accent text color */
    border: 2px solid var(--button-color); /* Border color */
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.2rem;
    width: 100%;
    cursor: pointer;
    box-shadow: none; /* No shadow by default */
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex; /* Center content */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.onetime-proceed-btn:hover {
    background-color: rgba(var(--button-color-rgb), 0.2); /* Light background on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.onetime-proceed-btn:active {
    background-color: rgba(var(--button-color-rgb), 0.15); /* Light background for selected */
    color: #fff; /* Darker text color for contrast */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for selected */
    transform: scale(1.02); /* Slightly larger on active */
}

/* New CSS classes for the added section */
.onetime-processing-container {
    background-color: #1e1e1e;
    color: white;
    border-radius: 5px;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #3e3e3e;
    margin-top: 2px; /* Adds space between existing content and processing container */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow: hidden; /* Ensure content doesn't overflow */
}

.onetime-link-no-underline {
    text-decoration: none;
}

.onetime-processing-content {
    text-align: left;
}

.onetime-processing-card {
    display: flex;
    align-items: center;
    gap: 15px;
}

.onetime-subtitle {
    margin-top: 0;
    font-size: 0.9rem;
    color: #aaa;
}

.onetime-title-article {
    font-size: 1.2rem;
    margin: 7px 0;
    font-weight: bold;
}

.onetime-description {
    font-size: 0.9rem;
    color: #ddd;
}

.onetime-upload-icon {
    width: 75px; /* Adjust the width to make the image smaller */
    height: 75px; /* Adjust the height to maintain aspect ratio */
}

.onetime-upload-language {
    width: 70px; /* Adjust the width to make the image smaller */
    height: 70px; /* Adjust the height to maintain aspect ratio */
}

.onetime-processing-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns the button to the start */
}

.onetime-processing-start-btn {
    background-color: transparent;
    color: #4add80;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: left;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.onetime-processing-start-btn:hover {
    text-decoration: underline;
}

/* Optional: Adjust the overall container to ensure vertical stacking */
.onetime-plan-box {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Ensure children take full width */
}


/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .onetime-plan-box {
        width: 90%;  
        padding: 20px;
    }

    .onetime-plan-options {
        flex-direction: column;
    }

    .onetime-plan-option {
        flex-direction: row;
        align-items: center;
    }

    .onetime-badge {
        font-size: 0.85rem;
    }
    
    .onetime-processing-container {
        display: none;
    }
}

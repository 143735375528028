/* Container for the entire mobile navbar */
.mobile-nav-container {
    background-color: #141414;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  /* Header row: left (logo) and right (Sign Up + hamburger) */
  .mobile-nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    height: 70px;
    padding: 0 1rem;
    border-bottom: 1px solid #3e3e3e;
  }
  
  /* Logo image styling */
  .mobile-logo-img {
    height: 50px;
    width: auto;
  }
  
  /* The right container that holds the Sign Up button and hamburger icon */
  .mobile-header-right {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  
  /* Hamburger button */
  .mobile-hamburger {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .mobile-hamburger svg {
    width: 24px;
    height: 24px;
  }
  
  .mobile-hamburger:hover {
    opacity: 0.8;
  }
  
  /* ======================
     Mobile Sign Up Button
     (with arrow animation)
     ====================== */
  .mobile-signup-btn {
    background-color: #fff;
    color: #141414;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.2s ease, color 0.2s ease;
    border: 1px solid #fff;
  }
  
  /* Container for the arrows */
  .mobile-arrow-container {
    display: inline-flex;      
    align-items: center;       
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-left: 0.5rem;
  }
  
  /* Both arrows */
  .mobile-arrow {
    display: inline-block;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  /* Primary arrow (initially visible) */
  .mobile-arrow-primary {
    position: relative;
    opacity: 1;
  }
  
  /* Secondary arrow (hidden initially, placed above) */
  .mobile-arrow-secondary {
    position: absolute;
    top: -100%;
    left: 0;
    opacity: 0;
  }
  
  /* Hover effect for the mobile sign-up button */
  .mobile-signup-btn:hover {
    background-color: #f0f0f0;
    color: #141414;
  }
  
  .mobile-signup-btn:hover .mobile-arrow-primary {
    transform: translateY(100%);
    opacity: 0;
  }
  
  .mobile-signup-btn:hover .mobile-arrow-secondary {
    transform: translateY(100%);
    opacity: 1;
  }
  
  /* ======================
     Slide-down Menu
     ====================== */
  .mobile-menu {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #141414;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    border-bottom: 1px solid #3e3e3e;
  }
  
  /* When menu is open, expand it */
  .mobile-menu.open {
    max-height: 80vh;
  }
  
  /* Menu list items */
  .mobile-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  .mobile-menu-item {
    padding: 1rem;
    font-size: 1rem;
    border-bottom: 0.5px solid #3e3e3e;
    cursor: pointer;
  }
  
  .mobile-menu-item:hover {
    background-color: #1f1f1f;
  }
  
  /* ======================
     Mobile Product Dropdown (used for both Product and API dropdowns)
     ====================== */
  .mobile-product-dropdown {
    list-style: none;
    margin: 0.5rem 0 0 1rem;
    padding: 0;
    background-color: #1f1f1f;
    border-left: 2px solid #3e3e3e;
  }
  
  .mobile-product-dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 0.5px solid #3e3e3e;
  }
  
  .mobile-product-dropdown-item:hover {
    background-color: #2a2a2a;
  }
  
  .mobile-product-dropdown-item img {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  /* ======================
     Buttons at bottom of the menu
     ====================== */
  .mobile-menu-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    margin-top: 5px;
  }
  
  .menu-link-mobile {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
  }
  
  .mobile-login-btn {
    color: #fff;
    text-decoration: none;
  }
  
  .mobile-demo-btn {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .mobile-demo-btn:hover {
    background-color: #2a2a2a;
  }
  
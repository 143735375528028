.close-sidebar {
  max-width: 80px;
  background-color: #141414; /* Dark background */
  color: #fff;
  padding: 20px 0; /* Adjusted padding for consistent spacing */
  box-sizing: border-box;
  border-right: 0.5px solid #3e3e3e;
  text-align: center;
  width: 100%;
}

.close-create-button {
  background-color: #1e1e1e;
  color: #fff;
  padding: 0;
  text-align: center;
  display: block;
  border-radius: 5px;
  font-size: 1.5em;
  font-weight: bold;
  width: 50px;
  height: 50px;
  line-height: 35px;
  text-decoration: none;
  margin: 0 auto 10px; /* Auto margin for horizontal centering */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.close-create-button:hover {
  background-color: #2c2f33; /* Slightly lighter dark on hover */
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  transform: translateY(-3px);
}

.link-icon-bump-close {
  margin-top: 3px;
  width: 40px; /* Adjust size as needed */
  height: 40px;
}

.close-dividersides {
  border-bottom: 0.5px solid #3e3e3e;
  margin: 20px 0;
}

.close-sidebar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 50px; /* Adjust height as needed */
  padding: 0;
  text-decoration: none; /* Remove underline from links */
}

.close-sidebar-link:hover {
  text-decoration: none; /* Ensure hover doesn't re-add underline */
}

.close-link-icon {
  transition: background-color 0.2s;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.close-link-selected {
  background-color: rgba(0, 0, 0, 0.2); /* Darkened background for the whole link */
}

.close-link-selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #45c072; /* Your green color */
}

/* Responsive Adjustments */
@media (max-width: 1280px) {
  .close-sidebar {
      width: 100px;
      padding: 15px 0;
  }

  .close-create-button {
      width: 40px;
      height: 40px;
  }

  .link-icon-bump-close {
      width: 35px;
      height: 35px;
  }

  .close-dividersides {
      margin: 15px 0;
  }

  .close-sidebar-link {
      height: 40px;
  }
}

@media (max-width: 768px) {
  .close-sidebar {
      width: 70px;
      padding: 15px 0;
  }

  .close-create-button {
      width: 37px;
      height: 37px;
  }

  .link-icon-bump-close {
      width: 32px;
      height: 32px;
  }
}

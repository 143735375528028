
  /* Override the left column layout to stack tiles vertically */
  .dapi-left-stack .unauthn-features-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .dapi-left-stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  /* Right column container */
  .dapi-dropdown-column-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  /* Container for multiple customer solution tiles */
  .dapi-solutions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  /* Each customer solution tile */
  .dapi-solution-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensures left-alignment */
    background-color: #141414;
    border: 1px solid #3e3e3e;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    text-decoration: none;

  }

  
  
  .dapi-solution-tile:hover {
    background-color: #2a2a2a;
    border-color: #5a5a5a;
  }
  
  /* Titles and descriptions */
  .dapi-solution-title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    color: #fff;
    text-align: left;
  }
  
  .dapi-solution-description {
    margin: 0 0 1rem 0;
    font-size: 0.85rem;
    color: #ccc;
    text-align: left;
  }
  
  /* Image styling (placed below text) */
  .dapi-solution-image {
    width: 100%;
    height: auto;
    object-fit: cover; /* Maintains aspect ratio, can crop if needed */
    border-radius: 4px;
  }
  
/* MAIN NAVBAR MENU CSS */
.sticky-header-wrapper {
  position: sticky;
  top: 0;
  z-index: 4;
}

.announcement-bar {
  background-color: #e0f7e9;
  text-align: center;
  padding: 12px;
  top: 0;
  z-index: 4;
}

.announcement-bar-text {
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  color: #006400;
  margin: 0;
  line-height: 1.5;
}

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #161616;
  z-index: 3;
  border-bottom: 0.5px solid #3e3e3e;
}

.navbar-sticky {
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #161616;
  border-bottom: 0.5px solid #3e3e3e;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #FFF;
}

.navbar-links {
  display: flex;
  gap: 40px;
  align-items: center;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  transition: color 0.3s;
  text-decoration: none;
  vertical-align: middle;
  color: #FFF;
  font-weight: 600;
  opacity: 0.9;
}

.mainnav-divider {
  width: 100%;
  height: 0.1px;
  background-color: #444;
  margin-top: 12px;
  margin-bottom: 12px;
}

.brand-and-toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - [Right Content Width]);
  flex-shrink: 0;
}

/* Sidebar TOGGLE CSS */
.sidebar-toggle {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
  position: relative;
  width: 30px;
  text-align: center;
  box-sizing: border-box;
}

.sidebar-toggle::after {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateX(5px);
  white-space: nowrap;
  background-color: #333;
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar-toggle:hover::after {
  opacity: 1;
}

.sidebar-toggle:focus {
  outline: none;
}

/* DROPDOWN MENU CSS for Profile */
.profile-dropdown {
  position: absolute;
  top: 100px;
  right: 65px;
  background-color: #141414;
  border-radius: 5px;
  padding: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.8s ease;
  font-weight: bold;
  border: 0.5px solid #3e3e3e;
}

.profile-dropdown.active {
  opacity: 1;
}

.profile-dropdown .nav-button {
  padding: 10px 30px 5px 5px;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: #FFF;
}

.email-display {
  padding: 12px;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #444;
}

.planNavIcon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
  vertical-align: middle;
}

.navbar-tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0e0e0e;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  opacity: 0;
  width: 100%;
}

.info-icon:hover + .navbar-tooltip-text,
.base-time-text:hover + .navbar-tooltip-text,
.bump-time-text:hover + .navbar-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.iconmenu {
  width: 35px;
  height: 35px;
  margin-right: 11px;
  vertical-align: middle;
}

.nav-button:hover {
  opacity: 1;
}

.nav-button.signup {
  background-color: #ffffff;
  color: #161616;
  padding: 10px 30px;
  border-radius: 4px;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.nav-button.signup:hover {
  transform: scale(1.02);
}

/* Profile Icon */
.profile-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.profile-icon:hover {
  border-color: white;
}

/* ADD MORE CREDITS & TIME CSS */
.add-credits-button {
  border-radius: 8px;
  padding: 15px 20px;
  background-color: #141414;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  border: 0.5px solid #3e3e3e;
}

.add-credits-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.add-credits-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}

.add-credits-button:active {
  transform: scale(0.98);
}

.base-time-text {
  font-size: 17px;
  font-weight: bold;
  color: #ffbf00;
  cursor: pointer;
}

.bump-time-text {
  font-size: 17px;
  font-weight: bold;
  color: #4add80;
  cursor: pointer;
}

.time-container {
  position: relative;
  display: flex;
  align-items: center;
}

.time-tooltip {
  position: absolute;
  bottom: -90px;
  left: 0;
  background-color: #212121;
  color: #fff;
  border-radius: 12px;
  padding: 16px;
  width: 170px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(10px) scale(0.9);
  left: -120px;
  text-align: left;
}

.time-container:hover .time-tooltip {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.tooltip-time {
  font-weight: 600;
  margin-right: 8px;
  color: #4add80;
}

.tooltip-expiry {
  margin-top: 10px;
  font-size: 0.9em;
  color: #ccc;
}

.tooltip-content {
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  height: 50px;
  width: auto;
}

@media screen and (max-width: 767px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    margin-left: -3vw;
  }

  .profile-icon {
    margin-right: -5vw;
  }

  .profile-dropdown {
    margin-right: -5vw;
  }

  .sidebar-toggle {
    display: none;
  }

  .announcement-bar-text {
    font-size: 14px;
    padding: 0px 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .navbar-links {
    flex-direction: row;
  }

  .navbar-brand {
    margin-left: 0px;
  }

  .sidebar-toggle {
    display: none;
  }
}

/*******************************************************
 * MAIN WRAPPER WITH SIDE PADDING
 *******************************************************/
 .main-div {
  padding: 4rem 1vw;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #161616;
}

/*******************************************************
 * CONTAINER SECTION (no extra padding here)
 *******************************************************/
.two-col-alternating {
  width: 100%;
  padding: 0;
  background-color: #161616;
}

/*******************************************************
 * HEADER ROW (2 columns: text left, text right)
 *******************************************************/
.header-row {
  display: flex;
  flex-wrap: nowrap; /* remain side by side unless very small screen */
  justify-content: space-between;
  margin: 0;         
  margin-bottom: 2rem; 
}

.header-col-left,
.header-col-right {
  flex: 0 0 50%;     /* each half the page */
  max-width: 50%;
  box-sizing: border-box;
  padding: 0;
  text-align: left;  /* left-aligned text by default */
}

/* 
   This ensures the entire right column content is 
   pushed to the right edge of its container, 
   but the text inside that container remains left-aligned.
*/
.header-col-right {
  display: flex;
  justify-content: flex-end; 
}

.header-right-inner {
  text-align: left;   
  max-width: 500px;   
}

.header-title {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  color: #fff;
}

.header-description {
  font-size: 1.2rem;
  line-height: 1.9;
  color: #fff;
  margin: 0;
  padding-top: 1rem;
}

/*******************************************************
 * ROW (2 columns)
 *******************************************************/
.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 0;
}

.left-col,
.right-col {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 0;
}

/*******************************************************
 * PLACEHOLDER STYLES
 *******************************************************/
.image-placeholder {
  height: 570px;
  background-color: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 1rem;
  border-radius: 20px;
}

.image-placeholder video,
.image-placeholder img {
  border-radius: 8px;
}


/*******************************************************
 * ACCORDION STYLES (right-col in Row 1)
 *******************************************************/
.accordion-col {
  padding-left: 5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.accordion-item {
  border-bottom: 1px solid #4add80;
  padding: 2rem 1rem;
  cursor: pointer;
  text-align: left;
  color: #fff;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-title {
  font-size: 1.4rem;
  margin: 0;
  font-weight: 600;
}

.accordion-icon {
  font-size: 2rem;
  font-weight: 100;
  color: #fff;
  user-select: none;
  margin-left: 1rem;
}

.accordion-content {
  margin-top: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #fff;
}

/*******************************************************
 * TEXT COLUMN
 *******************************************************/
.text-col:not(.left-col) {
  padding-left: 5rem;
}

.text-col{
  padding: 0;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  color: #fff;
}

.text-col h2 {
  margin: 0 0 1rem;
  font-size: 1.8rem;
  line-height: 1.3;
  
}

.text-col p {
  margin: 0 0 1rem;
  font-size: 1rem;
  line-height: 1.6;
  color: #bbb;
  
}

/*******************************************************
 * CTA LINK
 *******************************************************/
.inline-cta {
  display: inline-block;
  color: #4add80;
  text-decoration: none;
  font-weight: 400;
  margin-top: 0.5rem;
}

.inline-cta-arrow {
  transition: transform 0.2s;
}

.inline-cta:hover .inline-cta-arrow {
  transform: translateX(4px);
}

/*******************************************************
 * RESPONSIVE
 *******************************************************/
 @media (max-width: 768px) {
  .header-row,
  .row {
    flex-wrap: wrap;
  }

  /* Increase side padding for header columns on mobile */
  .header-col-left,
  .header-col-right {
    padding: 0 1.5rem;
  }

  /* Increase side padding for text columns */
  .text-col {
    padding: 0 1.5rem;
    padding-bottom: 2rem; /* maintain existing bottom padding if needed */
  }

  .header-title {
    font-size: 2.6rem;
  }

  .header-col-left,
  .header-col-right,
  .left-col,
  .right-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .left-col,
  .right-col {
    margin-top: 30px;
  }

  .image-placeholder {
    height: 450px;
  }

  /* Ensure header right content starts at the left on mobile */
  .header-col-right {
    justify-content: flex-start;
  }

  /* Remove any overriding left padding for non-left text columns */
  .text-col:not(.left-col) {
    padding-left: 1.5rem;
  }

  .accordion-col {
    padding-left: 0rem;
  }
}

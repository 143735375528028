 
 
  
  /* Right-side field container */
  .settings-field {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
/* Updated Connect Button styles to be bigger */
.settings-utube-connect-button {
    background-color: #2c2f2f;
    padding: 10px 22px; /* Increased padding */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    display: flex;
    align-items: center;
  }
  
  .settings-utube-connect-button.connected {
    background-color: #ffffff;
    cursor: default;
    opacity: 0.8;
  }
  
  .settings-utube-connect-button:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Increase the image size inside the button */
  .settings-create-connectyoutube {
    width: 40px;  /* Increased width */
    height: 30px; /* Increased height */
  }
  
/* Sidebar Container */
.sidebar {
  max-width: 320px;
  background-color: #141414;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-right: 0.5px solid #3e3e3e;
  width: 100%;
}

/* Sidebar Link Wrapper (Left-Aligned) */
.sidebar-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 10px 15px;
  text-decoration: none;
  background-color: #161616;
  color: #fff;
  border-radius: 4px; /* Always 4px border radius */
  transition: background-color 0.3s, border-left 0.3s;
}

/* Hover: Maintain same updated background color */
.sidebar-link:hover {
  background-color: #161616;
}

/* Active State: Accent left border with 80% opacity */
.sidebar-link.active {
  background-color: #161616; /* Main color remains the same */
  border-left: 4px solid rgba(74, 221, 128, 0.8);
}

/* Universal Sidebar Text Style */
.sidebar-text {
  font-size: 1.1em;
  font-weight: 600;
  margin-left: 10px;
  color: inherit;
}

/* Divider */
.dividersides {
  border-bottom: 0.5px solid #3e3e3e;
  margin: 10px 0;
}

/* Unique Plan Link Styling */
.plan-link {
  font-size: 0.9em;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  opacity: 0.8;
}

.plan-link:hover {
  opacity: 1;
}

/* Sidebar Footer and Settings Button */
.sidebar-footer-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.side-settings-button {
  background-color: #161616;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.2s, background-color 0.2s;
  opacity: 0.8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.side-settings-button:hover {
  opacity: 1;
  background-color: #161616;
}

/* Icon Styling */
.link-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Settings Icon Styling */
.side-settings-icon {
  width: 26px;
  height: 26px;
}

/* Plan Section */
.plan-section {
  margin-top: 40px;
  border-top: 0.5px solid #3e3e3e;
}
